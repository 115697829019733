import { css } from '@emotion/react';
import { COLOR, RADIUS } from '@constants';
import { isMobile } from '@utils/isMobile';

export const styles = {
  editorWrapper: isCollapsed => css`
    background: ${isCollapsed ? 'transparent' : COLOR.BASE_BACKGROUND};
    padding: 12px;
    vertical-align: middle;
    border: ${isCollapsed ? 'none' : `1px solid ${COLOR.NEUTRAL_3}`};
    ${isMobile() ? mobileStyles : webStyles}
    position: relative;
    display: block;
    font-weight: 400;
    line-height: 1.5em;
    overflow: hidden;
  `,
  inactive: css`
    border: none;
    padding: 0;
  `,
  readOnly: css`
    background: transparent;
  `,
  editorPlaceholder: css`
    position: absolute;
    left: 12px;
    top: 12px;
    pointer-events: none;
    color: ${COLOR.NEUTRAL_3};
  `
};

const webStyles = css`
  border-top: none;
  border-bottom-left-radius: ${RADIUS.S};
  border-bottom-right-radius: ${RADIUS.S};
`;

const mobileStyles = css`
  border-bottom: none;
  border-top-left-radius: ${RADIUS.S};
  border-top-right-radius: ${RADIUS.S};
`;
