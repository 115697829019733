import { ToolbarButton } from '../../ToolbarButton';
import type { IconKeys } from '@components/elements/Icon';
import { ColorPicker } from './ColorPicker';
import { ToolbarDropdown } from './ToolbarDropdown';
import React from 'react';
import { noop } from '@cyferd/client-engine';
import { css } from '@emotion/react';
import { COLOR, GAP } from '@constants';

interface ColorPickerButtonProps {
  color: string;
  iconName: IconKeys;
  tooltip: string;
  onColorChange: (value: string) => void;
}

export const ColorPickerButton = ({ color, onColorChange, iconName, tooltip }: ColorPickerButtonProps) => {
  return (
    <ToolbarDropdown
      trigger={
        <div css={styles.triggerContainer}>
          <ToolbarButton onClick={noop} iconName={iconName} tooltip={tooltip} />
          <div css={styles.indicator(color)} />
        </div>
      }
      options={<ColorPicker selectedColor={color} onChange={onColorChange} />}
      optionsCss={styles.optionsContainer}
    />
  );
};

const styles = {
  optionsContainer: css`
    max-width: none;
    max-height: none;
    width: 220px;
    display: flex;
    flex-wrap: wrap;
    gap: ${GAP.XS};
    padding: ${GAP.XS};
  `,
  indicator: color => css`
    background-color: ${color || COLOR.BASE_FOREGROUND};
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 90%;
    height: 4.5px;
    transform: translateX(-50%);
  `,
  triggerContainer: css`
    position: relative;
  `
};
