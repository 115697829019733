import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, TRANSITION_SPEED } from '@constants';

const height = '65px';

export const styles = {
  headerContainer: css`
    z-index: 9;
    position: fixed;
    width: 100%;
    height: ${height};
    margin: 0 auto ${GAP.S} auto;
    display: flex;
    justify-content: center;
    flex-direction: center;
    transition: background-color ${TRANSITION_SPEED};
    overflow: hidden;
  `,
  placeholder: css`
    height: ${height};
    margin-bottom: ${GAP.S};
    width: 100%;
  `,
  rightContainer: css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    gap: ${GAP.M};
  `,
  innerContainer: css`
    width: 100%;
    display: flex;
    padding: 0 ${GAP.M} 0 ${GAP.L};
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: ${GAP.M};
  `,
  iconContainer: css`
    margin-right: ${GAP.S};
  `,
  imageAsIcon: css`
    object-fit: cover;
    max-width: 140px;
    max-height: 60px;
  `,
  titleContainer: css`
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  title: css`
    font-size: ${FONT_SIZE.L};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.M};
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
  `,
  actionContainer: css`
    padding: ${GAP.XS};
    border-radius: 30px;
    margin-left: auto;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_2};
  `
};

export const newStyles = {
  ...styles,
  headerContainer: css`
    z-index: 9;
    position: sticky;
    top: 0;
    width: 100%;
    height: ${height};
    margin: 0 auto ${GAP.S} auto;
    display: flex;
    justify-content: center;
    flex-direction: center;
    transition: background-color ${TRANSITION_SPEED};
    overflow: hidden;
  `
};
