import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { CTA, CTAType } from '../CTA';

import { FileTextInput } from '../FileTextInput';
import { InputDescription } from '../InputDescription';
import type { IOptionMenu } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { ToolTip } from '../Tooltip';
import { getStyles } from './styles';
import { InfoForLabel } from '../InfoForLabel';
import { GeneralModel, Translate, ViewModel, isObject } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE } from '@constants';
import { Icon } from '../Icon';
import { FieldsetContent } from '../Fieldset';
import { InputErrorMesssage } from '../InputErrorMessage';
import { useHover } from '@utils';

export interface InputWrapperProps {
  allowFileInput?: boolean;
  children?: React.ReactNode;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  focused?: boolean;
  id?: string;
  label?: string;
  onChange?: (value: any) => void;
  onClear?: (event: any) => void;
  optionList?: IOptionMenu['optionList'];
  required?: boolean;
  testid?: string;
  value?: any;
  unlimitedHeight?: boolean;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
  isCollapsible?: boolean;
  startsCollapsed?: boolean;
  maxColumns?: number;
  collapsedFallbackDescription?: string;
  optionMenu?: ReactNode;
  count?: number;
  isContainerInput?: boolean;
  unframed?: boolean;
  disabledType?: GeneralModel.DisabledType;
  showPlaceholderLine?: boolean;
  inputContainerRef?: React.MutableRefObject<HTMLDivElement>;
  noLabelPadding?: boolean;
  disablePadding?: boolean;
}

export const InputWrapper = (props: InputWrapperProps) => {
  const {
    allowFileInput,
    children,
    description,
    disabled,
    errorMessage,
    focused,
    id,
    label,
    onChange,
    onClear,
    optionList,
    required,
    testid = 'input-wrapper',
    value,
    unlimitedHeight,
    info,
    color,
    count,
    optionMenu,
    isCollapsible,
    startsCollapsed,
    maxColumns,
    collapsedFallbackDescription,
    isContainerInput,
    unframed,
    disabledType,
    showPlaceholderLine,
    inputContainerRef,
    noLabelPadding,
    disablePadding
  } = props;

  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const styles = useMemo(() => getStyles({ unframed: unframed ?? isReadonly }), [isReadonly, unframed]);
  const { ref, hovered } = useHover();

  const colorValue = COLOR[color];
  const [isOpen, setOpen] = useState<boolean>(!startsCollapsed);

  const isEmpty = useMemo(() => [null, undefined, ''].includes(value as string), [value]);

  const safeIsCollapsible = !!isContainerInput && (isCollapsible || startsCollapsed);

  const safeDisabled = !isReadonly && !!disabled;

  const onToggleCollapse = useCallback(() => safeIsCollapsible && setOpen(prev => !prev), [safeIsCollapsible]);

  return (
    <div data-testid={`${testid}-container`}>
      <div css={[styles.inputWrapper, noLabelPadding && styles.inputWrapperNoPadding]}>
        <div css={styles.inputLabelWrapper}>
          {(!!label || !!info || !!required || !!count) && (
            <label data-testid={`${testid}-label`} htmlFor={id} css={[styles.label, safeDisabled && styles.labelDisabled]}>
              <ToolTip text={!!info ? undefined : label}>
                <div css={[styles.labelText, !!errorMessage && styles.labelTextError, safeIsCollapsible && styles.collapsible]} onClick={onToggleCollapse}>
                  {safeIsCollapsible && (
                    <span>
                      <CTA testid="input-toggle-btn" type={CTAType.LINK} icon={isOpen ? 'keyboard_arrow_down' : 'chevron_right'} />
                    </span>
                  )}
                  <Translate>
                    <span css={styles.labelTextContent}>{label}</span>
                  </Translate>
                  {required ? ' *' : ''}
                  <PreventClickPropagation containerCss={styles.infoBtn}>
                    <InfoForLabel label={label} value={info} testId={testid} />
                  </PreventClickPropagation>
                  {typeof count === 'number' && <span css={[styles.count, !!count && styles.countActive]}>{count}</span>}
                </div>
              </ToolTip>
            </label>
          )}
          <div ref={inputContainerRef}>
            <div
              ref={ref}
              data-testid={`${testid}-wrapper`}
              data-selector="input-border"
              css={[
                styles.input,
                !!isContainerInput && styles.containerInput,
                // No need to check if disabled because disabled inputs are not focusable
                focused && !errorMessage && styles.inputFocus,
                focused && !!errorMessage && styles.errorBorderColor,
                safeDisabled && styles.disabledInput,
                !!errorMessage && styles.inputError,
                // Check if disabled because disabled inputs can still be hovered
                hovered && !safeDisabled && !errorMessage && styles.inputHover,
                hovered && !safeDisabled && !!errorMessage && styles.errorBorderColor,
                (unlimitedHeight || isContainerInput) && styles.unlimitedHeight
              ]}
            >
              {!!colorValue && <div css={styles.colorBorder(colorValue)} />}

              {!!isContainerInput && (
                <div css={styles.content} data-testid="framed">
                  <div
                    css={[styles.subtitleContainer, !isOpen && styles.collapsible]}
                    data-selector="subtitle-container"
                    onClick={!!isOpen ? undefined : onToggleCollapse}
                  >
                    <div>
                      {!isReadonly && <InputDescription active={!isOpen && hovered} description={description || (!isOpen && collapsedFallbackDescription)} />}
                    </div>
                    {!!isOpen && (
                      <PreventClickPropagation>
                        <OptionMenu defaultBtnType={ViewModel.CTAType.LINK} optionList={optionList} />
                      </PreventClickPropagation>
                    )}
                    {optionMenu}
                  </div>
                  {!!isOpen && <FieldsetContent maxColumns={maxColumns}>{children}</FieldsetContent>}
                </div>
              )}

              {!isContainerInput && (
                <>
                  <div css={styles.children(disablePadding)} data-selector="children-container">
                    {children}
                  </div>
                  {!isEmpty && !!onClear && !disabled && (
                    <PreventClickPropagation containerCss={styles.clearBtn}>
                      <div data-selector="clear">
                        <CTA testid={`${testid}-clear-input-btn`} type={CTAType.SEEMLESS} disabled={disabled} onClick={onClear}>
                          <Icon name="close" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XM} />
                        </CTA>
                      </div>
                    </PreventClickPropagation>
                  )}
                  {(!!optionList?.length || optionMenu) && (
                    <div css={styles.actionButtonsContainer} data-selector="input-action-btn-container">
                      <PreventClickPropagation>
                        <OptionMenu
                          defaultBtnType={CTAType.LINK}
                          optionList={optionList
                            ?.filter?.(isObject)
                            .map?.(o => (o.size === ViewModel.CTASize.MEDIUM ? o : { ...o, size: ViewModel.CTASize.MEDIUM }))}
                          defaultBtnSize={ViewModel.CTASize.MEDIUM}
                        />
                      </PreventClickPropagation>
                      {optionMenu}
                    </div>
                  )}
                </>
              )}
            </div>
            {!!isReadonly && !!showPlaceholderLine && !!isEmpty && (
              <div
                css={[
                  styles.placeholderLine,
                  !!errorMessage && /* istanbul ignore next */ isReadonly && /* istanbul ignore next */ styles.placeholderLineError
                ]}
              />
            )}
          </div>
        </div>
      </div>
      {!isContainerInput && !isReadonly && <InputDescription showPadding={!unframed || !isReadonly} description={description} />}
      {!!allowFileInput && <FileTextInput onChange={onChange} testid={`${testid}-fileInput`} />}
      <InputErrorMesssage id={id} message={errorMessage} testid={testid} showLine={!isEmpty && isReadonly} />
    </div>
  );
};
