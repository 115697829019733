import { memo, useMemo } from 'react';

import { GeneralModel, Translate } from '@cyferd/client-engine';

import { COLOR, FOREGROUND_COLOR, GAP, HC_COLOR } from '@constants';
import { useHover } from '@utils';
import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';
import { Icon } from '@components/elements/Icon';
import { Spinner } from '@components/elements/Spinner';
import { getColors, styles } from './styles';
import type { ChipProps } from './types';
import { ChipType } from '../types';

export const Chip = memo(
  ({
    id,
    testid = 'chip',
    type = ChipType.DEFAULT,
    title,
    description,
    color,
    icon,
    active,
    activeValues = [],
    disabled,
    disabledType,
    showCheck,
    compact,
    onClick,
    dropdownOpen,
    isLoading
  }: ChipProps) => {
    const isDropdown = type === ChipType.DROPDOWN;
    const { ref, hovered } = useHover();

    const safeDisabled = !!disabled && disabledType === GeneralModel.DisabledType.DISABLED;

    const safeColorName: GeneralModel.Color.ThemeColor = useMemo(() => {
      if (safeDisabled) return 'NEUTRAL_3_5';
      return COLOR[color] ? color : 'BRAND_3';
    }, [color, safeDisabled]);

    const iconColor = useMemo(() => {
      if (safeDisabled) return COLOR.NEUTRAL_1_5;
      return active ? FOREGROUND_COLOR[safeColorName] : HC_COLOR[safeColorName];
    }, [active, safeColorName, safeDisabled]);

    const textColor = getColors({ safeDisabled, color: safeColorName, active }).text;
    return (
      <ToolTip text={<TooltipInfo title={title} description={description} />}>
        <div id={id} css={styles.container({ color: safeColorName, active, compact, safeDisabled, disabled, dropdownOpen, type })} ref={ref}>
          <CTA testid={id || testid} type={CTAType.SEEMLESS} onClick={!isDropdown ? onClick : undefined} disabled={disabled || isLoading} hideLoading={true}>
            <div css={[styles.content, (disabled || isLoading) && styles.disabled]}>
              {!!icon && (
                <IconImage
                  title=""
                  icon={icon}
                  iconProps={{
                    outlined: true,
                    fill: iconColor,
                    size: compact ? '15px' : '19px',
                    css: { lineHeight: 0 }
                  }}
                  imageProps={{ css: { width: 21, height: 21, objectFit: 'cover', borderRadius: '100%', margin: `-${GAP.XS} 0 -${GAP.XS} -${GAP.S}` } }}
                />
              )}
              <span css={styles.text({ color: safeColorName, active, safeDisabled })}>
                <Translate>{title}</Translate>
              </span>
              {!!isDropdown && activeValues?.length > 0 && (
                <div data-testid={`${testid}-counter`} css={styles.activeValuesCounter}>
                  {activeValues.length}
                </div>
              )}
              {!!isDropdown && isLoading && <Spinner size={GAP.M} />}
              {!!isDropdown && !isLoading && (!active || hovered) && <Icon name="keyboard_arrow_down" size="16px" fill={textColor} />}
              {((!isDropdown && showCheck && active) || (isDropdown && active && !hovered && !isLoading)) && (
                <Icon testid={`${testid}-check-icon`} name="check" size="16px" fill={textColor} />
              )}
            </div>
          </CTA>
        </div>
      </ToolTip>
    );
  }
);
