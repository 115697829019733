import { COLOR, SECONDARY_COLOR } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css, keyframes } from '@emotion/react';

const animation = keyframes`
from { background-position-x: 1000px; }
to { background-position-x: 0px; }
`;

const animationAlt = keyframes`
  0% {
    transform:  translateX(0) scaleX(0.0);
  }
  50% {
    transform:  translateX(50%) scaleX(0.4);
  }
  70% {
    transform:  translateX(50%) scaleX(0.5);
  }
  100% {
    transform:  translateX(100%) scaleX(0.1);
  }
`;

export const getStyles = (color: GeneralModel.Color.ThemeColor, size: number) => ({
  container: css`
    height: ${size}px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR.BASE_BACKGROUND};
  `,
  bar: css`
    width: 110%;
    height: 100%;
    position: absolute;
    left: -10%;
    background-color: ${SECONDARY_COLOR[color]};
    background-image: repeating-linear-gradient(45deg, transparent, transparent 20px, ${COLOR[color]} 20px, ${COLOR[color]} 40px);
    animation: ${animation} 25s linear infinite;
    will-change: background-position;
  `,
  containerAlt: css`
    height: ${size}px;
    background-color: ${COLOR.NEUTRAL_5};
    width: 100%;
    overflow: hidden;
  `,
  barAlt: css`
    width: 100%;
    height: 100%;
    background-color: ${COLOR[color]};
    animation: ${animationAlt} 1s ease-in-out infinite;
    transform-origin: 0% 50%;
    will-change: transform;
  `
});
