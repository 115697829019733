import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EMPTY, catchError } from 'rxjs';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import {
  ErrorBoundary,
  ViewModel,
  getOptionListFieldIdList,
  getParsedActionChildren,
  isObject,
  useGeneralFetch,
  useOnEntityChange,
  useParsedCursors,
  useRecordActionsParser,
  useSearchFetch
} from '@cyferd/client-engine';

import { FiltersModal } from '@components/elements/FiltersModal';
import { Kanban } from '@components/elements/Kanban';
import { SelectDropdown } from '@components/elements/SelectDropdown';
import { CONTAINER_WIDTH, TRANS } from '@constants';
import { useGetElementSize } from '@utils';
import { getLabel } from '@utils/getLabel';
import { CyWrapperContext } from '../CyWrapper';
import { styles } from './styles';
import { QuickFilters } from '@components/elements/QuickFilters';
import { Header } from '@components/elements/Header';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { SearchInput } from '@components/elements/SearchInput';
import { ProgressBar } from '@components/elements/ProgressBar';
import { Spinner } from '@components/elements/Spinner';
import { Pagination } from '@components/elements/Pagination';
import { BBContainer } from '@components/elements/BBContainer';
import { ModalContainerContext } from '@components/elements/Modal/ModalContainerContext';
import { InfoMessage } from './components/InfoMessage';

export const CyKanban = ({
  id,
  componentName,
  title,
  value,
  actionListChildren,
  headerListChildren,
  initialFetchCriteria,
  fixedFilter,
  optionListField,
  paginationHidden,
  searchStringHidden,
  advancedFiltersHidden,
  quickFiltersHidden,
  refreshHidden,
  listActionsHidden,
  recordActionsHidden,
  avoidFetch,
  optionFieldDisabled,
  optionFieldHidden,
  moveDisabled,
  onFetch,
  onClickItem,
  onChangeItem,
  onChangeOptionField,
  onColumnAction,
  effectChildren,
  framed = true,
  fitToPage
}: ViewModel.CyKanbanProps) => {
  const { useFetchCollectionModel, useOnRefresh, useParsers, renderSchemaForm } = useContext(CyWrapperContext);
  const testid = 'CyKanban';
  const [internalValue, setInternalValue] = useState<ViewModel.CyKanbanProps['value']>(value);

  const modalContainerContext = useContext(ModalContainerContext);
  const safeFramed = modalContainerContext?.instance ? false : framed;

  const { parseList, parseData } = useParsers(value?.query);
  const parsedList = useMemo(() => parseList({ entity: internalValue?.query, list: internalValue?.list }), [internalValue, parseList]);

  const quickFilters = useMemo(
    () => value?.query?.quickFilters?.filter(f => isObject(f) && !parseData(f.hidden)) as GeneralModel.FetchCriteria['quickFilters'],
    [parseData, value?.query?.quickFilters]
  );

  const { ref, width } = useGetElementSize();

  const { onGeneralFetch, canFetch, isLoading } = useGeneralFetch({ onFetch, fixedCursor: { fixedFilter }, avoidFetch });
  const { onSearch, setSearchString, searchString, canSearch, onRefresh, onInitialFetch } = useSearchFetch({
    onFetch: onGeneralFetch,
    isLoading,
    value,
    canFetch,
    initialFetchCriteria
  });

  const isFirstLoad = isLoading && !value;

  const fetchCollectionModel = useFetchCollectionModel();

  const optionListFieldIdList = useMemo(() => getOptionListFieldIdList(parsedList.definitionList), [parsedList.definitionList]);
  const safeOptionListField = optionListField || optionListFieldIdList[0];
  const options = useMemo(
    () =>
      optionListFieldIdList.map(o => ({
        value: o,
        label: getLabel(parsedList.definitionMap[o]?.displayNamePath)
      })) as GeneralModel.JSONSchemaMetadata['optionList'],
    [optionListFieldIdList, parsedList.definitionMap]
  );

  const safeOnClickItem = isLoading ? undefined : onClickItem;

  /* istanbul ignore next line | @todo */
  const onInternalChangeItem = useCallback(
    (item?: ApiModel.ApiRecord) => {
      if (!onChangeItem) return EMPTY;

      setInternalValue(prev => ({ ...prev, list: prev.list.map(i => (i?.id === item?.id ? item : i)) }));

      return onChangeItem(item).pipe(
        catchError(() => {
          setInternalValue(value);
          return EMPTY;
        })
      );
    },
    [onChangeItem, value]
  );

  useOnRefresh({ id, componentName }, onRefresh);

  const { initialCursor, currentCursor } = useParsedCursors({ initialFetchCriteria, fixedFilter, value });
  useOnEntityChange(initialCursor, currentCursor, onInitialFetch);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const parsedHeaderListChildren = useMemo(() => getParsedActionChildren(headerListChildren, value), [headerListChildren, value]);
  const listActions = !listActionsHidden && getParsedActionChildren(value?.query?.listActions);
  const parseListActions = useRecordActionsParser(listActions);
  const parsedListActions = useMemo(() => parseListActions({ item: value, index: null }), [parseListActions, value]);
  const completeParsedActionChildren = useMemo(
    () =>
      [...parsedListActions, ...parsedHeaderListChildren].map(item => ({
        testid: `${testid}-header-action-btn-${item.label}`,
        label: item.label,
        type: item.type || CTAType.TERTIARY,
        disabled: isLoading || !!item.disabled,
        onClick: event => item.onClick?.(value as any, event),
        image: item.icon,
        status: (item as any).status,
        tooltip: item.helperText,
        color: item.color,
        important: item.important,
        size: ViewModel.CTASize.SMALL
      })),
    [isLoading, parsedHeaderListChildren, parsedListActions, value]
  );

  const shouldRenderHeader = !!(title || completeParsedActionChildren.length || (canFetch && (!searchStringHidden || !advancedFiltersHidden)));

  return (
    <BBContainer framed={safeFramed} fitToPage={fitToPage}>
      <div id={id} css={styles.container} data-testid={testid} ref={ref}>
        <div data-testid="effects">{effectChildren}</div>
        <InfoMessage value={value} />
        <ErrorBoundary>
          {shouldRenderHeader && (
            <div css={styles.headerContainer}>
              <Header
                testid="CyKanban-header"
                title={
                  <div css={styles.titleContainer}>
                    {!!title && <h1>{title}</h1>}
                    {!optionFieldHidden && (
                      <SelectDropdown
                        disabled={isLoading || optionFieldDisabled}
                        value={safeOptionListField}
                        options={options}
                        onChange={onChangeOptionField}
                      />
                    )}
                  </div>
                }
                titleControls={
                  !quickFiltersHidden &&
                  !!quickFilters?.length && (
                    <div>
                      <QuickFilters
                        config={quickFilters}
                        cursor={value?.query?.cursor}
                        disabled={isLoading}
                        onFetch={onGeneralFetch}
                        schema={value?.query?.schema}
                      />
                    </div>
                  )
                }
                controls={
                  <div css={styles.controls}>
                    <div css={styles.headerActionList}>
                      <OptionMenu maxImportant={4} defaultBtnType={ViewModel.CTAType.LINK} optionList={completeParsedActionChildren} />
                    </div>
                    <div css={styles.headerSearchContainer}>
                      {canSearch && (
                        <>
                          {!searchStringHidden && (
                            <div css={styles.searchInputContainer}>
                              <SearchInput
                                testid={`${testid}-search-input`}
                                value={searchString}
                                onChange={setSearchString}
                                onClick={onSearch}
                                searchDelay={500}
                                disabled={isLoading}
                                compact={width <= CONTAINER_WIDTH.XS}
                              />
                            </div>
                          )}

                          {!advancedFiltersHidden && (
                            <div>
                              <FiltersModal
                                entity={value?.query}
                                displaySorting={true}
                                fetchCollectionModel={fetchCollectionModel}
                                cursor={value?.query?.cursor}
                                onSubmit={onGeneralFetch}
                                testid={`${testid}-advanced-filters`}
                                renderSchemaForm={renderSchemaForm}
                                disabled={isLoading}
                              />
                            </div>
                          )}

                          {!refreshHidden && (
                            <div>
                              <CTA
                                onClick={onRefresh}
                                testid={`${testid}-refresh-btn`}
                                icon="refresh"
                                disabled={isLoading}
                                hideLoading={true}
                                type={CTAType.LINK}
                                size={ViewModel.CTASize.LARGE}
                                tooltip={TRANS.client.buttons.refresh}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                }
              />
              {!!isLoading && !!value && (
                <div style={{ marginTop: 5, marginBottom: -5 }}>
                  <ProgressBar color="BRAND_2" size={5} alt={true} />
                </div>
              )}
            </div>
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          <div css={styles.content}>
            {isFirstLoad && (
              <div css={styles.spinnerContainer}>
                <Spinner testid={`${testid}-spinner`} />
              </div>
            )}
            {!isFirstLoad && (
              <Kanban
                id={id}
                optionListField={safeOptionListField}
                value={parsedList}
                recordActions={!recordActionsHidden && value?.query?.recordActions}
                actionListChildren={actionListChildren}
                isLoading={isLoading}
                moveDisabled={moveDisabled}
                onClickItem={safeOnClickItem}
                onChangeItem={onInternalChangeItem}
                onColumnAction={onColumnAction}
              />
            )}
          </div>
        </ErrorBoundary>
        <ErrorBoundary>
          {!!canFetch && !paginationHidden && (
            <Pagination
              cursor={internalValue?.query?.cursor}
              count={internalValue?.list?.length || 0}
              onFetch={onGeneralFetch}
              disabled={isLoading}
              testid={`${testid}-pagination`}
            />
          )}
        </ErrorBoundary>
      </div>
    </BBContainer>
  );
};

CyKanban.displayName = ViewModel.DisplayName.CY_KANBAN;
