import { CTA, CTAType } from '@components/elements/CTA';
import { ViewModel } from '@cyferd/client-engine';
import type { IconKeys } from '@components/elements/Icon';
import { styleHelpers } from '@utils/styleHelpers';
import { COLOR } from '@constants';

interface ToolbarButtonProps {
  onClick: () => void;
  iconName: IconKeys;
  tooltip?: string;
  disabled?: boolean;
  active?: boolean;
}

export const ToolbarButton = ({ disabled, onClick, iconName, tooltip, active }: ToolbarButtonProps) => {
  return (
    <div css={styleHelpers.flexCenter}>
      <CTA
        icon={{ name: iconName, fill: active ? COLOR.BRAND_1 : COLOR.NEUTRAL_1 }}
        disabled={disabled}
        outlined={true}
        type={CTAType.LINK}
        size={ViewModel.CTASize.MEDIUM}
        tooltip={tooltip}
        onClick={onClick}
      />
    </div>
  );
};
