import { Z_INDEX } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  menuContainer: css`
    position: relative;
  `,
  childContainer: css`
    position: fixed;
    z-index: ${Z_INDEX.DROPDOWN};
  `
};
