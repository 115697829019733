import { GeneralModel, noop } from '@cyferd/client-engine';
import { Global } from '@emotion/react';
import { useGetElementSize, useSafeState } from '@utils';
import type { Context, Dispatch, MutableRefObject, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { BROWSER, THEME } from '@constants';
import { themeMap, themeStyleMap } from '@components/styles/themes';
import { useUIStore } from '../../client-app/state-mgmt/zustand/bridgeProvider';
import { UAParser } from 'ua-parser-js';

export type UIContextType = Context<{
  openModals: MutableRefObject<any>[];
  setOpenModals: Dispatch<SetStateAction<MutableRefObject<any>[]>>;
  state: WPLStateContext;
  setState: Dispatch<SetStateAction<WPLStateContext>>;
  runtimeTheme: THEME;
  size: GeneralModel.AppSize;
  browserName: string;
}>;

export interface WPLStateContext {
  themeKey: THEME;
  [key: string]: any;
}

export const UIContext: UIContextType = createContext({
  openModals: [],
  setOpenModals: noop as any,
  state: {} as WPLStateContext,
  setState: noop as any,
  runtimeTheme: null,
  size: null,
  browserName: null
});

export type UIProviderProps = PropsWithChildren<{
  themeStorageKey?: string;
}>;

const mediaQuery = '(prefers-color-scheme: dark)';
/* istanbul ignore next */
const getThemeBasedOnMediaQuery = (event: any) => (event?.matches ? THEME.DARK : THEME.LIGHT);
const matchMedia = window.matchMedia || noop;

export const UIProvider = ({ themeStorageKey = 'theme', children }: UIProviderProps) => {
  const [openModals, setOpenModals] = useSafeState([]);
  const [state, setState] = useState({
    themeKey: (() => {
      const saved = localStorage.getItem(themeStorageKey) as THEME;
      return Object.values(THEME).includes(saved) ? saved : THEME.OS;
    })()
  });
  const [osTheme, setOsTheme] = useSafeState(getThemeBasedOnMediaQuery(matchMedia(mediaQuery)));
  const runtimeTheme: THEME = useMemo(
    () => (!Object.values(THEME).includes(state?.themeKey) || state?.themeKey === THEME.OS ? osTheme : state?.themeKey),
    [osTheme, state?.themeKey]
  );
  const { ref, width } = useGetElementSize();

  const setRuntimeTheme = useUIStore(state => state.setRuntimeTheme);
  const setSize = useUIStore(state => state.setSize);

  useEffect(() => {
    setRuntimeTheme(runtimeTheme);
  }, [runtimeTheme, setRuntimeTheme]);

  /* istanbul ignore next line */
  const size: any = useMemo(() => {
    if (width <= 576 || /Mobi|Mobile/.test(navigator?.userAgent)) return GeneralModel.AppSize.S;
    if (!width || width <= 1440) return GeneralModel.AppSize.M;
    return GeneralModel.AppSize.L;
  }, [width]);

  useEffect(() => {
    setSize(size);
  }, [size, setSize]);

  const browserName = useMemo(() => {
    const parser = new UAParser();
    const browser = parser.getBrowser().name;
    switch (browser) {
      case 'Chrome':
        return BROWSER.CHROME;
      case 'Safari':
        return BROWSER.SAFARI;
      case 'Firefox':
        return BROWSER.FIREFOX;
      case 'Edge':
        return BROWSER.EDGE;
      case 'Opera':
        return BROWSER.OPERA;
      case 'IE':
      case 'Internet Explorer':
        return BROWSER.INTERNET_EXPLORER;
      default:
        return BROWSER.UNKNOWN;
    }
  }, []);

  const value = useMemo(
    () => ({ openModals, setOpenModals, state, setState, runtimeTheme, size, browserName }),
    [openModals, setOpenModals, state, runtimeTheme, size, browserName]
  );

  useEffect(() => {
    localStorage.setItem(themeStorageKey, state?.themeKey);
  }, [state?.themeKey, themeStorageKey]);

  /* istanbul ignore next */
  useEffect(() => {
    const modeMe = (event: any) => setOsTheme(getThemeBasedOnMediaQuery(event));
    matchMedia(mediaQuery)?.addEventListener('change', modeMe);
    return () => matchMedia(mediaQuery)?.removeEventListener('change', modeMe);
  }, [setOsTheme]);

  return (
    <div ref={ref}>
      <UIContext.Provider value={value}>
        <Global styles={themeStyleMap[runtimeTheme]} />
        {children}
      </UIContext.Provider>
    </div>
  );
};

export const useCurrentTheme = (): GeneralModel.Color.Theme => {
  const { runtimeTheme } = useContext(UIContext);

  return themeMap[runtimeTheme];
};
