import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT, RADIUS, SECONDARY_COLOR } from '@constants';

export const getStyles = ({ unframed }: { unframed?: boolean }) => ({
  inputFocus: css`
    outline: none;
    border-color: ${COLOR.BRAND_1};
  `,
  disabledInput: css`
    background-color: ${COLOR.NEUTRAL_9};
    cursor: not-allowed;
    box-shadow: none;
    input,
    textarea,
    [data-selector='dropdown-option'] * {
      cursor: not-allowed !important;
      color: ${COLOR.NEUTRAL_1_5};
    }
  `,
  inputHover: css`
    border-color: ${COLOR.BRAND_1};
  `,
  errorBorderColor: css`
    border-color: ${COLOR.HC_5};
    /* Needed when styles applied to an input tag */
    outline: none;
  `,
  inputError: css`
    border-color: ${COLOR.HC_5};
  `,
  labelDisabled: css`
    background: transparent;
  `,
  children: (disablePadding: boolean | undefined) => css`
    flex: 1;
    max-width: 100%;
    overflow-x: auto;
    padding: ${getChildrenPadding(unframed, disablePadding)};
  `,
  label: css`
    height: 16px;
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    line-height: normal;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    user-select: none;
  `,
  infoBtn: css`
    display: flex;
  `,
  labelText: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
    button span {
      color: ${COLOR.NEUTRAL_2};
    }
  `,
  labelTextContent: css`
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  inputWrapper: css`
    display: flex;
    gap: ${GAP.XS};
    padding-top: 6px; // makes up for label overflow
    [data-selector='clear'] button {
      line-height: 0;
    }
  `,
  inputWrapperNoPadding: css`
    padding-top: 0;
  `,
  inputLabelWrapper: css`
    flex-grow: 1;
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  input: css`
    display: flex;
    width: 100%;
    color: ${COLOR.NEUTRAL_1};
    transition: border-color 0.2s ease;
    line-height: 1;
    height: ${INPUT_HEIGHT};
    align-items: center;
    ${unframed
      ? 'border: none; background-color: transparent;'
      : `
        border: 1px solid ${COLOR.NEUTRAL_3};
        border-radius: ${RADIUS.S};
        background-color: ${SECONDARY_COLOR.NEUTRAL_6};
    `}
  `,
  actionButtonsContainer: css`
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    padding: ${GAP.XS} ${GAP.XS} 0 ${GAP.XS};
    ${unframed ? `` : `border-left: 1px solid ${COLOR.NEUTRAL_3};`}
  `,
  unlimitedHeight: css`
    height: auto;
  `,
  clearBtn: css`
    align-self: flex-start;
    height: 30px;
    display: flex;
    align-items: center;
    padding-right: ${GAP.XS};
  `,
  colorBorder: (colorValue: string) => css`
    align-self: stretch;
    background-color: ${colorValue};
    border-radius: ${RADIUS.S};
    width: ${GAP.XS};
    ${unframed ? `margin: ${GAP.XS} ${GAP.XS} ${GAP.XS} 0;` : `margin: ${GAP.XS} 0 ${GAP.XS} ${GAP.XS};`}
  `,
  count: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${GAP.XS};
    padding: 0 ${GAP.XS};
    background-color: ${COLOR.NEUTRAL_4};
    height: 16px;
    min-width: 16px;
    border-radius: 16px;
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
    text-shadow: none;
  `,
  countActive: css`
    background-color: ${SECONDARY_COLOR.BRAND_3};
  `,
  containerInput: css`
    position: relative;
    min-height: ${INPUT_HEIGHT};
    background-color: ${COLOR.TRANSPARENT};
  `,
  content: css`
    flex: 1;
    padding: ${GAP.S};
  `,
  subtitleContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  labelTextError: css`
    button span {
      color: ${COLOR.HC_5};
    }
  `,
  collapsible: css`
    cursor: pointer;
  `,
  placeholderLine: css`
    width: 100%;
    border-bottom: 1px solid ${COLOR.NEUTRAL_3};
  `,
  placeholderLineError: css`
    border-color: ${COLOR.HC_5};
  `
});

const getChildrenPadding = (unframed, disablePadding) => {
  if (unframed) {
    return `0 ${GAP.S} ${GAP.XS} 0`;
  } else {
    if (disablePadding) {
      return '0';
    } else {
      return `${GAP.XS} ${GAP.S}`;
    }
  }
};
