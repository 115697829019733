import { COLOR, FONT_SIZE, GAP, RADIUS, SCROLLBAR, SHADOW, Z_INDEX } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const styles = {
  container: (type: ViewModel.ModalType) => css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.M};
    box-shadow: ${SHADOW.M};
    display: flex;
    flex-direction: column;
    &:focus: {
      outline: none;
    }
    z-index: 2;

    overflow: initial;
    width: 100%;
    padding: ${GAP.M};

    ${(() => {
      switch (type) {
        case ViewModel.ModalType.LARGE:
          return `max-width: 1200px;`;
        case ViewModel.ModalType.FULL_SCREEN:
          return `max-width: calc(100vw - 2 * ${GAP.M});`;
        case ViewModel.ModalType.COMPACT:
          return `max-width: 460px;`;
        case ViewModel.ModalType.REGULAR:
        default:
          return `max-width: 850px;`;
      }
    })()};
  `,
  headerContainer: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${GAP.M};
    overflow: initial !important;
    span,
    div,
    p {
      overflow: initial !important;
    }
  `,
  header: css`
    display: flex;
    align-items: center;
    h2 {
      color: ${COLOR.NEUTRAL_1};
      font-size: ${FONT_SIZE.XXM};
    }
  `,
  innerContainer: css`
    display: flex;
    flex-direction: column;
  `,
  limitInnerContainerHeight: css`
    max-height: calc(90vh - ${GAP.XS});
  `,
  iconContainer: css`
    margin-right: ${GAP.XS};
  `,
  content: css`
    overflow: auto !important;
    padding: ${GAP.S} 0 ${GAP.M} 0;
    height: 100%;
    ${SCROLLBAR}
    color: ${COLOR.NEUTRAL_1};
  `,
  overlayContainer: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.MODAL};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  placeOnTop: css`
    align-items: flex-start !important;
    top: ${GAP.XXL}!important;
  `,
  overlayContainerOpen: css`
    width: 100%;
    height: 100%;
  `,
  overlay: css`
    background-color: ${COLOR.ABSOLUTE_BLACK};
    opacity: 0.6;
    position: fixed;
    inset: 0;
    z-index: 1;
  `,
  navbar: css`
    display: flex;
    justify-content: flex-end;
  `,
  descriptionContainer: css`
    margin-top: ${GAP.XXS};
    color: ${COLOR.NEUTRAL_1};
  `,
  footer: css`
    display: flex;
    justify-content: flex-end;
  `,
  titleDescriptionContainer: css`
    word-break: break-word;
  `
};
