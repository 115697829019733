import type { THEME } from '@constants';
import type { ClientEngineContextValue, GeneralModel } from '@cyferd/client-engine';
import { create } from 'zustand';

interface UIStore {
  runtimeTheme: THEME;
  size: GeneralModel.AppSize;
  onConfirm: ClientEngineContextValue['modalInteraction']['onConfirm'] | null;
  onAlert: ClientEngineContextValue['modalInteraction']['onAlert'] | null;
  setRuntimeTheme: (theme: THEME) => void;
  setSize: (size: GeneralModel.AppSize) => void;
  setOnConfirm: (fn: ClientEngineContextValue['modalInteraction']['onConfirm']) => void;
  setOnAlert: (fn: ClientEngineContextValue['modalInteraction']['onAlert']) => void;
}

export const useUIStore = create<UIStore>(set => ({
  runtimeTheme: null,
  size: null,
  onConfirm: null,
  onAlert: null,
  setRuntimeTheme: theme => set({ runtimeTheme: theme }),
  setSize: size => set({ size }),
  setOnConfirm: fn => set({ onConfirm: fn }),
  setOnAlert: fn => set({ onAlert: fn })
}));
