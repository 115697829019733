import { CONTAINER_WIDTH, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  innerContainer: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  `,
  route: css`
    flex: 1;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    width: 100%;
    transition: width ${TRANSITION_SPEED};
    justify-content: center;
  `,
  routeContainer: css`
    max-width: ${CONTAINER_WIDTH.MAX}px;
    width: 100%;
    justify-content: center;
    min-height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  `,
  spinnerContainer: css`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  sidepanelContent: css`
    width: 100%;
  `
};
