import { useCallback, useContext, useId, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, filter, mergeMap, take, takeLast, takeUntil, tap } from 'rxjs';

import type { GeneralModel } from '@cyferd/client-engine';
import { ApiModel, ViewModel, mergeTruthy, useUnmountObservable } from '@cyferd/client-engine';

import { CyForm } from '../../CyForm';
import { CyModal } from '../../../CyModal';
import { CyWrapperContext } from '../../../CyWrapper';
import { TRANS } from '@constants';

/* istanbul ignore next line | patch to predict the association key */
const getOppositeAccKey = (key: string) => (key?.replace(/(.*)_/, '') === 'parent' ? key?.replace(/_(.*)/, '_child') : key?.replace(/_(.*)/, '_parent'));

export type CreateAssociationModalProps = {
  associatedSchema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  onAssociationCreated: () => Observable<ApiModel.APIAction>;
  record?: ApiModel.ApiRecord;
} & Partial<ViewModel.CyModalProps>;

export const CreateAssociationModal = ({ associatedSchema, apiQuery, onAssociationCreated, record, ...props }: CreateAssociationModalProps) => {
  const { useOnGetEntity, useOnCreateAssociatedRecord } = useContext(CyWrapperContext);
  const modalId = useId();
  const [formValue, setFormValue] = useState<ViewModel.CyListProps['value']>(undefined);
  const onDestroy$ = useUnmountObservable();

  const onInitialFetch = useOnGetEntity();
  const onFetch = () => {
    const associatedCursor = associatedSchema?.metadata?.association?.cursor;
    return onInitialFetch(associatedCursor?.collectionId).pipe(
      takeUntil(onDestroy$),
      filter(action => action?.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA && action?.payload?.value),
      take(1),
      tap(({ payload }) => {
        const oppAssociationKey = getOppositeAccKey(associatedCursor?.associationKey);
        const newFormValue = mergeTruthy(payload.value, {
          record: {
            [`${oppAssociationKey}`]: [{ ...record, $r: { id: apiQuery?.cursor?.id } }],
            [`$$${getOppositeAccKey(associatedCursor?.associationKey)}`]: { add: [{ id: apiQuery?.cursor?.id, recordTitle: record?.recordTitle }] }
          },
          query: { schema: { properties: { [oppAssociationKey]: { metadata: { disabled: true } } } } }
        });
        setFormValue(newFormValue);
      })
    );
  };

  const onChange = useCallback((v: ViewModel.CyFormProps['value']) => {
    setFormValue(v);
    return EMPTY;
  }, []);

  const onCreateAssociatedRecord = useOnCreateAssociatedRecord();
  const onSave = useCallback(
    (record: ApiModel.ApiRecord) =>
      onCreateAssociatedRecord({ ...formValue, query: { ...formValue?.query, schema: {} }, record }).pipe(
        takeLast(1),
        mergeMap(() => onAssociationCreated())
      ),
    [formValue, onAssociationCreated, onCreateAssociatedRecord]
  );

  return (
    <CyModal id={modalId} {...props} title={`Create new associated ${associatedSchema?.title}`}>
      <CyForm
        maxColumns={1}
        type={ViewModel.CyFormType.STEPPER}
        ctaLabel={TRANS.client.buttons.save}
        value={formValue}
        onChange={onChange}
        onSave={onSave}
        onFetch={onFetch}
        recordActionsHidden={true}
      />
    </CyModal>
  );
};
