import { css } from '@emotion/react';
import { COLOR, GAP } from '@constants';

export const VerticalDivider = () => {
  return <div css={styles} />;
};

const styles = css`
  border-left: 1px solid ${COLOR.NEUTRAL_3};
  margin: 0 ${GAP.XS};
`;
