import { useMemo } from 'react';

import { Translate } from '@cyferd/client-engine';

import { GAP, TRANS } from '@constants';
import { useHover } from '@utils';
import { DropdownMenu } from '@components/elements/DropdownMenu';
import { Icon } from '@components/elements/Icon';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import type { ChipProps } from '@components/elements/Chips/Chip';
import { type ChipDropdownProps } from '@components/elements/Chips/ChipDropdown';
import { ChipType } from '@components/elements/Chips';
import { styles } from './styles';
import { CompactQuickFiltersMenu } from './CompactQuickFiltersMenu';
import { Spinner } from '@components/elements/Spinner';

export interface ICompactQuickFilters {
  quickFilters: (ChipProps | (ChipDropdownProps & { type: ChipType }))[];
}

export const CompactQuickFilters = ({ quickFilters }: ICompactQuickFilters) => {
  const { ref: hoverRef, hovered } = useHover();
  const activeCounter = useMemo(
    () =>
      quickFilters?.reduce((acc, f) => {
        if (f.type === ChipType.DEFAULT && f.active) return acc + 1;
        if (f.type === ChipType.DROPDOWN && f.activeValues?.length) return acc + f.activeValues.length;
        return acc;
      }, 0),
    [quickFilters]
  );

  const isLoading = useMemo(() => !!quickFilters.find(f => f.isLoading), [quickFilters]);

  if (!quickFilters?.length) return null;

  return (
    <DropdownMenu
      vertical={4}
      horizontalResponsive={false}
      renderTrigger={({ onClick, ref }) => (
        <div
          ref={ref}
          css={[styles.trigger, isLoading && styles.disabledTrigger]}
          onClick={isLoading ? undefined : onClick}
          data-testid="compact-quick-filters"
        >
          <div ref={hoverRef}>
            <div css={styles.triggerContent}>
              <Icon name="filter_list" />
              <span css={styles.title}>
                <Translate>{TRANS.client.buttons.quickFilters}</Translate>
              </span>
              {isLoading && <Spinner size={GAP.M} />}
              {!isLoading && activeCounter > 0 && !hovered && <div css={styles.activeCount}>{activeCounter}</div>}
              {!isLoading && (!!hovered || activeCounter === 0) && <Icon name="keyboard_arrow_down" />}
            </div>
          </div>
        </div>
      )}
    >
      <PreventClickPropagation>
        <CompactQuickFiltersMenu quickFilters={quickFilters} />
      </PreventClickPropagation>
    </DropdownMenu>
  );
};
