import { useCallback, useMemo, useRef, useState } from 'react';
import type { GeneralModel } from '@cyferd/client-engine';
import { listToMap } from '@cyferd/client-engine';
import { InputWrapper } from '@components/elements/InputWrapper';
import { styles } from './styles';
import { Chip } from '@components/elements/Chips/Chip';
import { useOutsideClick } from '@utils/useOutsideClick';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import type { IOptionMenu } from '@components/elements/OptionMenu';
import { Icon } from '@components/elements/Icon';
import { MultiOptionDropdownMenu } from './MultiOptionDropdownMenu';

export interface IMultiOptionDropdown {
  optionList?: GeneralModel.JSONSchemaMetadata['optionList'];
  label?: string;
  testid?: string;
  value?: (string | number)[];
  disabled?: boolean;
  required?: boolean;
  onChange: (value: (string | number)[]) => void;
  description?: string;
  errorMessage?: string;
  info?: string;
  menuOptionList?: IOptionMenu['optionList'];
  disabledType?: GeneralModel.DisabledType;
}

export const MultiOptionDropdown = ({
  testid = 'multi-option-dropdown',
  optionList = [],
  value,
  label,
  disabled,
  onChange,
  info,
  errorMessage,
  description,
  required,
  menuOptionList,
  disabledType
}: IMultiOptionDropdown) => {
  const [isOpen, setOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement>();
  const safeValue = useMemo(() => (Array.isArray(value) ? value : []), [value]);
  const optionMap = useMemo(() => listToMap(optionList, {}, 'value'), [optionList]);

  const onEdit = useCallback(() => {
    /* istanbul ignore else */
    if (!disabled) setOpen(true);
  }, [disabled]);

  const onClickOutside = useCallback(() => setOpen(false), []);

  const onSelect = useCallback(
    (opt: GeneralModel.InputOption) => {
      const v = opt.value;
      const hasValue = safeValue.includes(v);
      if (hasValue) return onChange(safeValue.filter(item => v !== item));
      return onChange([...safeValue, v]);
    },
    [onChange, safeValue]
  );

  const outsideRef: React.LegacyRef<HTMLDivElement> = useOutsideClick(onClickOutside);

  const onClear = () => onChange([]);
  const onSelectAll = () => onChange([...new Set([...safeValue, ...optionList.map(o => o.value)])]);

  return (
    <div ref={outsideRef}>
      <div onClick={onEdit} data-testid={testid} css={styles.mainContainer}>
        <InputWrapper
          testid={testid}
          disabled={!!disabled}
          disabledType={disabledType}
          required={required}
          optionList={menuOptionList}
          description={description}
          label={label}
          info={info}
          errorMessage={errorMessage}
          value={value?.join?.('-')}
          showPlaceholderLine={true}
          onClear={onClear}
          inputContainerRef={mainRef}
        >
          {!!safeValue.length ? (
            <div css={styles.inputValue}>
              <div css={styles.chipsContainer}>
                {safeValue.map((selectedValue, index) => {
                  const item = optionMap[selectedValue] || { value: selectedValue, label: String(selectedValue) };
                  return (
                    <div css={styles.chipContainer} key={`${item.value}-${index}`}>
                      <PreventClickPropagation>
                        <Chip
                          title={item.label}
                          color={(item.color || 'BRAND_1') as GeneralModel.Color.ThemeColor}
                          icon={item.image as GeneralModel.IconName}
                          id={String(item.value)}
                          disabled={disabled}
                          disabledType={disabledType}
                          showCheck={false}
                          active={true}
                          compact={true}
                          onClick={() => onSelect(item)}
                        />
                      </PreventClickPropagation>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div css={styles.expandIconContainer}>{!disabled && <Icon name="keyboard_arrow_down" size="14px" />}</div>
          )}
        </InputWrapper>

        {!!isOpen && (
          <MultiOptionDropdownMenu
            onSelectAll={onSelectAll}
            onSelect={onSelect}
            testid={testid}
            selectedOptions={safeValue}
            optionList={optionList}
            ref={mainRef}
          />
        )}
      </div>
    </div>
  );
};

MultiOptionDropdown.displayName = 'MultiOptionDropdown';
