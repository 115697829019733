import type { DOMConversionMap, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import { $createParagraphNode, $getRoot, createEditor, DecoratorNode } from 'lexical';
import { InfoBlock } from '../components/InfoBlock';
import type { InfoBlockType } from '../components/InfoBlock/types';
import { $createExtendedTextNode } from './ExtendedTextNode';

export class InfoBlockNode extends DecoratorNode<JSX.Element> {
  type: InfoBlockType;
  text: LexicalEditor;

  constructor(type: InfoBlockType = 'info', text?: LexicalEditor, key?: NodeKey) {
    super(key);
    this.type = type;
    this.text = text || createEditorWithText(type);
  }

  static getType() {
    return 'info-block';
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.className = 'info-block';
    element.style.display = 'inline-block';
    element.style.width = '100%';

    return element;
  }

  updateDOM(): false {
    return false;
  }

  setType(type: InfoBlockType): void {
    const writable = this.getWritable();
    writable.type = type;
  }

  decorate() {
    return <InfoBlock type={this.type} editor={this.text} nodeKey={this.getKey()} />;
  }

  exportDOM(): { element: HTMLElement } {
    const element = document.createElement('span');

    const textContent = this.text.getEditorState().read(() => {
      const root = $getRoot();
      return root.getTextContent();
    });

    element.className = 'info-block';
    element.setAttribute('data-type', this.type);
    element.setAttribute('data-text', textContent);

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('info-block')) return null;

        return {
          conversion: $parseInfoBlockElement,
          priority: 2
        };
      }
    };
  }

  exportJSON() {
    return {
      version: 1,
      type: this.type,
      text: this.text.getEditorState().toJSON(),
      nodeType: 'info-block'
    };
  }

  static importJSON(serializedNode): InfoBlockNode {
    const infoBlockNode = new InfoBlockNode(serializedNode.type);

    const caption = serializedNode.text;
    const nestedEditor = infoBlockNode.text;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return infoBlockNode;
  }

  static clone(node: InfoBlockNode): InfoBlockNode {
    return new InfoBlockNode(node.type, node.text, node.__key);
  }
}

const $parseInfoBlockElement = domNode => {
  const type = domNode.getAttribute('data-type');
  const text = domNode.getAttribute('data-text');

  if (type) {
    const nestedEditor = createEditorWithText(type, text);

    const node = $createInfoBlockNode(type, nestedEditor);
    return { node };
  }

  return null;
};

export const $createInfoBlockNode = (type: InfoBlockType = 'info', text?: LexicalEditor): InfoBlockNode => {
  return new InfoBlockNode(type, text);
};

export const $isInfoBlockNode = (node: LexicalNode | null | undefined): node is InfoBlockNode => {
  return node instanceof InfoBlockNode;
};

const createEditorWithText = (type: InfoBlockType = 'info', text: string = '') => {
  const editor = createEditor();

  const initialText = text || `This is ${type} block...`;

  editor.update(() => {
    const root = $getRoot();

    root.clear();

    const paragraphNode = $createParagraphNode();
    paragraphNode.append($createExtendedTextNode(initialText));

    root.append(paragraphNode);
  });

  return editor;
};
