import { useFeatureFlag } from '@utils';

interface HasFlagRendererProps {
  flag: string;
  NewComponent: React.ComponentType;
  LegacyComponent: React.ComponentType;
  [key: string]: unknown;
}

export const HasFlagRenderer = ({ flag, NewComponent, LegacyComponent, ...props }: HasFlagRendererProps) => {
  const flagCheck = useFeatureFlag({ flag });

  const ComponentToRender = flagCheck ? NewComponent : LegacyComponent;

  return <ComponentToRender {...props} />;
};
