import type { LexicalNode, NodeKey, DOMConversionMap } from 'lexical';
import { DecoratorNode } from 'lexical';
import { FileBlock } from '../components/FileBlock';
import type { FileObject } from '../types';

export class FileNode extends DecoratorNode<JSX.Element> {
  file: FileObject;
  downloadUrl: string = '';

  constructor(file, downloadUrl = '', key?: NodeKey) {
    super(key);
    this.file = file;
    this.downloadUrl = downloadUrl;
  }

  static getType() {
    return 'file';
  }

  static clone(node) {
    return new FileNode(node.file, node.downloadUrl, node.__key);
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.className = 'file-block';
    element.style.display = 'inline-block';

    return element;
  }

  updateDOM() {
    return false;
  }

  static importJSON(serializedNode) {
    return new FileNode(serializedNode.file);
  }

  exportJSON() {
    return {
      version: 1,
      type: 'file',
      file: this.file
    };
  }

  exportDOM() {
    const element = document.createElement('a');

    element.className = 'file-block';
    element.href = this.downloadUrl;
    element.textContent = this.file?.name;

    element.setAttribute('size', this.file?.size?.toString());
    element.setAttribute('download', this.file?.name);
    element.setAttribute('mime-type', this.file?.mimeType);
    element.setAttribute('id', this.file?.id);
    element.setAttribute('name', this.file?.name);
    element.setAttribute('encoding', this.file?.encoding);

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      a: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('file-block')) return null;

        return {
          conversion: $parseFileBlockElement,
          priority: 2
        };
      }
    };
  }

  setFile(file: FileObject, downloadUrl: string) {
    const writable = this.getWritable();

    writable.file = file;
    writable.downloadUrl = downloadUrl;
  }

  decorate() {
    return <FileBlock nodeKey={this.getKey()} file={this.file} />;
  }
}

const $parseFileBlockElement = domNode => {
  const name = domNode.getAttribute('name');
  const size = domNode.getAttribute('size');
  const mimeType = domNode.getAttribute('mime-type');
  const encoding = domNode.getAttribute('encoding');
  const id = domNode.getAttribute('id');
  const downloadUrl = domNode.getAttribute('href');

  const fileObject = { name, size, mimeType, encoding, id };

  const node = $createFileNode(fileObject, downloadUrl);

  return { node };
};

export const $createFileNode = (file: FileObject | null, downloadUrl = '') => {
  return new FileNode(file, downloadUrl);
};

export const $isFileNode = (node: LexicalNode | null | undefined): node is FileNode => {
  return node instanceof FileNode;
};
