import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS, SHADOW } from '@constants';

export const styles = {
  linkEditor: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 10;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    background-color: ${COLOR.TOP_BACKGROUND};
    box-shadow: ${SHADOW.S};
    border-radius: ${RADIUS.S};
    transition: opacity 0.5s;
    will-change: transform;
    gap: ${GAP.M};
    padding: ${GAP.XS};
  `
};
