import { css, type Interpolation, type Theme } from '@emotion/react';

import { isIcon } from '@utils/isIcon';
import type { IconKeys, IconProps } from './Icon';
import { Icon } from './Icon';
import { useState } from 'react';

const styles = {
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
};

export type IconImageProps = {
  icon: string;
  title: string;
  iconProps?: { css?: Interpolation<Theme> } & Omit<IconProps, 'name' | 'title'>;
  imageProps?: { css?: Interpolation<Theme> } & Pick<IconProps, 'size'>;
  testid?: string;
};

export const IconImage = ({ icon, title, iconProps, imageProps, testid = 'icon' }: IconImageProps) => {
  const [showDefaultIcon, setShowDefault] = useState(false);
  if (!icon) return null;
  if (isIcon(icon)) {
    return (
      <div css={[styles.iconContainer, iconProps?.css]}>
        <Icon testid={testid} name={icon as IconKeys} title={title} {...iconProps} />
      </div>
    );
  }
  /* istanbul ignore next line */
  if (showDefaultIcon) {
    return (
      <div css={[styles.iconContainer, iconProps?.css, styles.iconContainer]}>
        <Icon testid={testid} name="broken_image" title={title} {...iconProps} />
      </div>
    );
  }
  /* istanbul ignore next line */
  const handleShow = () => setShowDefault(true);

  return (
    <img
      data-testid={testid}
      alt={String(icon)}
      onError={handleShow}
      src={String(icon)}
      css={[styles.iconContainer, imageProps?.css]}
      style={{ width: imageProps?.size }}
    />
  );
};
