import type { ComponentProps, PropsWithChildren } from 'react';
import { useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs';

import type { ApiModel } from '@cyferd/client-engine';
import { ViewModel, decodeQueryString, encodeQueryString, ofTypeSetData, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { ENV, LIST_ROUTE, QUERY_PARAM, TRANS } from '@constants';
import { GlobalContext } from '../../../state-mgmt/GlobalState';
import { styles } from './styles';
import { ViewHeader } from '@components/platform/ViewHeader';
import { TabList } from '@components/elements/TabList';

export type Props = PropsWithChildren<{
  title?: string;
  subtitle?: string;
  icon?: string;
  color?: string;
  routeList: { route: string; label: string }[];
  rootPath: string;
  isCurrentDraft: boolean;
  hasChanges?: boolean;
  additionalButtonList?: ViewModel.ViewHeaderProps['actionListChildren'];
  optionMenuProps?: ComponentProps<typeof TabList>['optionMenuProps'];
  tabIOptionMenu?: ComponentProps<typeof TabList>['optionMenuProps'];
  onSave: (options?: any) => Observable<any>;
  onRemove: () => Observable<any>;
  onSetOriginalValue: (item: any) => void;
}>;

export const SubHeader = ({
  title,
  subtitle,
  icon,
  color,
  routeList,
  rootPath,
  additionalButtonList = [],
  hasChanges,
  optionMenuProps,
  tabIOptionMenu,
  onSave,
  onRemove,
  onSetOriginalValue
}: Props) => {
  const { deps } = useContext(GlobalContext);
  const { pathname, search } = useLocation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onDestroy$ = useUnmountObservable();
  const finalize = useFinalizeWhileMounted();

  const list = routeList.filter(({ label }) => !!label);

  /* istanbul ignore next line */
  const onSubmitSave = useCallback(
    (options?: any) => {
      setLoading(true);
      onSave(options)
        .pipe(
          takeUntil(onDestroy$),
          ofTypeSetData<ApiModel.ApiValue>(),
          tap(response => {
            onSetOriginalValue(response?.record);
            navigate({ search: encodeQueryString({ ...decodeQueryString(search), [QUERY_PARAM.RECORD_ID]: response?.query?.cursor?.id }) }, { replace: true });
          }),
          finalize(() => setLoading(false))
        )
        .subscribe();
    },
    [finalize, navigate, onDestroy$, onSave, onSetOriginalValue, search]
  );

  /* istanbul ignore next line */
  const onInternalSave = useCallback(() => onSubmitSave({ force: false }), [onSubmitSave]);

  const onInternalRemove = useCallback(() => {
    deps.modalInteraction.onConfirm(
      {
        status: ViewModel.Status.INFO,
        icon: 'delete',
        title: TRANS.client.SubHeader.modalTitle,
        description: TRANS.client.SubHeader.modalDescription,
        cancelLabel: TRANS.client.buttons.goBack,
        confirmLabel: TRANS.client.buttons.delete
      },
      () => {
        setLoading(true);
        onRemove()
          .pipe(
            tap({ complete: () => navigate(`${rootPath}${LIST_ROUTE}`) }),
            takeUntil(onDestroy$),
            finalize(() => setLoading(false))
          )
          .subscribe();
      }
    );
  }, [deps.modalInteraction, onRemove, onDestroy$, finalize, navigate, rootPath]);

  return (
    <>
      <ViewHeader
        title={title}
        subtitle={subtitle}
        icon={icon as any}
        color={color as any}
        actionListChildren={[
          !!onSave && {
            onClick: onInternalSave as any,
            type: ViewModel.CTAType.ACTION,
            icon: 'save' as any,
            label: TRANS.client.buttons.save,
            important: true,
            disabled: isLoading || (!hasChanges && !ENV.IS_TEST)
          },
          optionMenuProps as any,
          ...additionalButtonList,
          !!onRemove && {
            onClick: onInternalRemove as any,
            icon: 'delete' as any,
            label: TRANS.client.buttons.deletePermanently,
            color: 'RD_4' as any
          }
        ].filter(Boolean)}
      />
      {list.length > 1 && (
        <div className={styles.headerInnerContainer}>
          <TabList
            tabList={list.map(({ route, label }) => ({ title: `${rootPath}${route}`, displayName: label }))}
            activeTab={pathname}
            onChangeTab={route => navigate({ pathname: route, search: window.location.search }) as any}
            optionMenuProps={tabIOptionMenu}
          />
        </div>
      )}
    </>
  );
};
