import type { PropsWithChildren } from 'react';
import { useContext } from 'react';

import { styles } from './styles';
import type { SerializedStyles } from '@emotion/react';
import { useGetElementSize, useGetItemHeight } from '@utils';
import { ModalContainerContext } from '../Modal/ModalContainerContext';
import { ViewModel } from '@cyferd/client-engine';

export type BBContainerProps = PropsWithChildren<{
  framed?: boolean;
  fitToPage?: boolean;
  cssOverload?: SerializedStyles;
  testId?: string;
}>;

export const BBContainer = (props: BBContainerProps) => {
  const { framed, fitToPage, children, cssOverload, testId = 'bb-container' } = props;

  if (!framed && !fitToPage) return children;

  if (framed && !fitToPage) {
    return (
      <div data-testid={testId} css={[styles.framed, cssOverload]}>
        {children}
      </div>
    );
  }

  return <BBContainerFitToPage {...props} />;
};

const BBContainerFitToPage = (props: BBContainerProps) => {
  const { framed, children, cssOverload, testId = 'bb-container' } = props;
  const { ref, top } = useGetElementSize();
  const modalContainerContext = useContext(ModalContainerContext);
  const isInsideModal = !!modalContainerContext?.instance;
  const height = useGetItemHeight(ViewModel.LayoutHeightPreset.REMAINING, isInsideModal ? Math.max(top + 70, 0) : top);

  return (
    <div ref={ref} data-testid={testId} style={{ overflow: 'auto', height }} css={[!!framed && styles.framed, cssOverload]}>
      {children}
    </div>
  );
};
