import type { SvgProps } from '../types';
import { COLOR } from '@constants';

export const FormatIcon = ({ size, fill = COLOR.NEUTRAL_1, ...props }: SvgProps) => {
  return (
    <svg width={size || 20} height={size || 20} {...props} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_459_2529" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22">
        <rect y="0.5" width="21" height="21" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_459_2529)">
        <path
          d="M5 17.1429L9.59375 4.89285H11.7812L16.375 17.1429H14.275L13.1813 13.9929H8.2375L7.1 17.1429H5ZM8.85 12.2429H12.525L10.7312 7.16785H10.6438L8.85 12.2429Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
