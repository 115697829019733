import { useCallback, useContext } from 'react';
import type { TokenSet } from '@cyferd/client-engine';
import { ClientEngineContext, safeParse } from '@cyferd/client-engine';
import { ajax } from 'rxjs/ajax';
import { map, shareReplay } from 'rxjs';
import { useOnDownloadFile } from '@utils/cyWrapperContextValue/actionHooks';

export const useFileManagement = () => {
  const { platformConfig } = useContext(ClientEngineContext);
  const dispatchDownloadFile = useOnDownloadFile();

  const onDownloadFile = useCallback((fileId: string) => dispatchDownloadFile({ id: fileId }), [dispatchDownloadFile]);

  const onUploadFile = useCallback(
    (file: File) => {
      const formData = new FormData();

      const headers = {
        'Content-Disposition': 'form-data; name=file',
        enctype: 'multipart/form-data',
        Authorization: `Bearer ${safeParse<TokenSet>(localStorage.getItem('token-set'))?.access}`
      };

      formData.append('file', file);

      const request$ = ajax({ url: platformConfig?.fileUploadUrl, body: formData, method: 'POST', headers }).pipe(
        map(r => r.response),
        shareReplay()
      );

      return request$;
    },
    [platformConfig?.fileUploadUrl]
  );

  return {
    onUploadFile,
    onDownloadFile
  };
};
