import type { RichTextEditorProps } from './RichTextEditor';
import { RichText as NewRichText, RichTextEditor as NewRichTextEditor } from './RichTextEditor';
import type { RichTextProps } from './LegacyRichTextEditor';
import { LegacyRichText, LegacyRichTextEditor } from './LegacyRichTextEditor';
import { useFeatureFlag } from '@utils';

// TODO: remove and cleanup all legacy when verified

export const RichTextEditor = (props: RichTextEditorProps) => {
  const hasFlag = useFeatureFlag({ flag: 'EARLY_ACCESS' });

  return hasFlag ? <NewRichTextEditor {...props} /> : <LegacyRichTextEditor {...props} />;
};

export const RichText = (props: RichTextProps) => {
  const hasFlag = useFeatureFlag({ flag: 'EARLY_ACCESS' });

  return hasFlag ? <NewRichText {...props} /> : <LegacyRichText {...props} />;
};
