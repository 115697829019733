import type { ChipProps } from '@components/elements/Chips/Chip';
import type { ChipDropdownProps } from '@components/elements/Chips/ChipDropdown';
import { ChipType } from '@components/elements/Chips';
import { Checkbox } from '@components/elements/Checkbox';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { styles } from './styles';
import { FONT_SIZE } from '@constants';

interface ICompactQuickFiltersMenu {
  quickFilters: (ChipProps | (ChipDropdownProps & { type: ChipType }))[];
  testid?: string;
}

const insertSeparators = array =>
  array.reduce((result, current, index, originalArray) => {
    const newResult = [...result, current];

    if (index === originalArray.length - 1) return newResult;

    const next = originalArray[index + 1];
    if (current.type !== next.type || (current.type === ChipType.DROPDOWN && next.type === ChipType.DROPDOWN)) {
      return [...newResult, undefined];
    }
    return newResult;
  }, []);

export const CompactQuickFiltersMenu = ({ quickFilters, testid = 'compact-quickfilters-menu' }: ICompactQuickFiltersMenu) => {
  if (!quickFilters?.length) return null;
  const filtersWithSeparators = insertSeparators(quickFilters);
  return (
    <div data-testid={testid} css={styles.container}>
      {filtersWithSeparators.map((props, index) => {
        if (!props) return <div key={`separator-${index}`} css={styles.separator} data-testid={`${testid}-separator`} />;
        const { id, color, title, type } = props;
        switch (type) {
          case ChipType.DEFAULT:
            const { active, onClick } = props as ChipProps;
            return (
              <div
                key={`${id}-${index}`}
                data-testid={`${testid}-custom-filter`}
                css={[styles.listItem, styles.customQuickfilter, styles.clickableContainer, active && styles.active]}
                onClick={onClick}
              >
                <Checkbox id={id} color={color} testid={`${testid}-custom-filter-${index}-checkbox`} value={active} />
                {!!title && <div>{title}</div>}
              </div>
            );
          case ChipType.DROPDOWN:
            return (
              <div key={`${id}-${index}`} data-testid={`${testid}-option-filter`}>
                <div css={[styles.listItem, styles.optionQuickFilterTitle]}>
                  <IconImage icon={props.icon} title={props.icon} iconProps={{ size: FONT_SIZE.XXM }} imageProps={{ size: FONT_SIZE.XXM }} />
                  {title}
                </div>
                <div css={styles.optionsContainer}>
                  {(props as ChipDropdownProps).options?.map(({ label, onClick, image, value }, optionIndex) => {
                    const active = (props as ChipDropdownProps).activeValues?.includes(value);
                    return (
                      <div key={optionIndex} onClick={onClick} css={[styles.listItem, styles.option, styles.clickableContainer, active && styles.active]}>
                        <Checkbox id={id} color={color} testid={`${testid}-option-filter-${index}-checkbox`} value={active} />
                        <IconImage icon={image} title={image} iconProps={{ size: FONT_SIZE.XXM }} imageProps={{ size: FONT_SIZE.XXM }} />
                        {label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
