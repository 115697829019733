import { COLOR, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  framed: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.M};
    height: 100%;
    border-radius: ${RADIUS.M};
  `
};
