import { css } from '@emotion/react';
import { COLOR, FONT_SIZE, GAP, HC_COLOR, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { defaultTabColor } from '../TabList';

export const styles = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
  `,
  containerBorder: css`
    border-right: 1px solid ${COLOR.NEUTRAL_3};
  `,
  formContainer: css`
    gap: ${GAP.XXS};
  `,
  expandedContainer: css`
    max-width: 150px;
  `,
  expandBtn: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${GAP.S};
    margin-bottom: ${GAP.XS};
  `,
  btnContainer: css`
    button {
      width: 100%;
    }
  `,
  verticalFormTab: css`
    border: 1px solid ${COLOR.NEUTRAL_4};
    border-top-left-radius: ${GAP.XS};
    border-bottom-left-radius: ${GAP.XS};
    border-right: none;
    background-color: ${SECONDARY_COLOR.NEUTRAL_6};
  `,
  verticalTab: css`
    transition: none;
    padding: 0 ${GAP.S};
    position: relative;
  `,
  tabExpanded: css`
    padding-left: ${GAP.S};
    padding-right: ${GAP.M};
    grid-template-columns: min-content 1fr;
  `,
  activeTab: css`
    z-index: 1;
  `,
  iconContainer: css`
    height: ${FONT_SIZE.L};
    width: ${FONT_SIZE.L};
  `,
  iconContainerHidden: css`
    visibility: hidden;
  `,
  circle: css`
    width: ${GAP.S};
    height: ${GAP.S};
    background-color: ${COLOR.NEUTRAL_1};
    border-radius: 50%;
  `,
  activeCircle: (color?: GeneralModel.Color.ThemeColor) => css`
    background-color: ${HC_COLOR[color] || defaultTabColor};
  `,
  disabledCircle: css`
    background-color: ${COLOR.NEUTRAL_2};
  `,
  tabLabel: css`
    font-size: ${FONT_SIZE.XS};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
  `,
  activeBar: css`
    height: 30px;
    width: ${GAP.XS};
    border-radius: ${GAP.XS};
    background-color: transparent;
    transition: all ${TRANSITION_SPEED};
    position: absolute;
    right: 0;
  `,
  formActivebar: css`
    position: absolute;
    height: 100%;
    width: ${GAP.XS};
    border-top-left-radius: ${GAP.XS};
    border-bottom-left-radius: ${GAP.XS};

    z-index: 1;
  `,
  borderRight: css`
    position: absolute;
    right: 0px;
    background-color: ${COLOR.NEUTRAL_3};
    width: ${GAP.XXXS};
    height: 100%;
  `
};
