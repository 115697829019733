import { css } from '@emotion/react';

import { COLOR, GAP, RADIUS, SHADOW, Z_INDEX } from '@constants';

export const styles = {
  listContainer: css`
    position: fixed;
    background-color: ${COLOR.TOP_BACKGROUND};
    z-index: ${Z_INDEX.DROPDOWN};
    padding: ${GAP.S} ${GAP.S} 0 ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    max-height: 270px;
    overflow: auto;
  `,
  preventClickContainer: css`
    display: flex;
    align-items: center;
  `,
  dropdownIconContainer: css`
    width: 100%;
  `,
  dropdownContainer: css`
    width: 100%;
    [data-selector='dropdown-option'] {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  optionContainer: css`
    margin-bottom: ${GAP.S};
  `,
  inputContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  paginationInputContainer: css`
    height: 30px;
  `,
  chevronContainer: css`
    cursor: pointer;
  `,
  paginationChevronContainer: css`
    top: 10px;
  `,
  selected: css`
    flex: 1;
  `,
  search: css`
    flex: 1;
  `
};
