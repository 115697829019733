import { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';
import { COLOR } from '@constants';

export const ColorPicker = ({ selectedColor, onChange }) => {
  return (
    <>
      {colors.map(color => (
        <div css={[styles.colorItem, styles.color(color), selectedColor === color && styles.selectedColorItem]} onClick={() => onChange(color)} key={color} />
      ))}
      <div css={[styles.colorItem, styles.transparentColorItem]} onClick={() => onChange('')}></div>
    </>
  );
};

const colors = GeneralModel.Color.clientColorGroupList.flat().map(color => `var(--${color})`);

const styles = {
  colorItem: css`
    width: 20px;
    height: 20px;
    cursor: pointer;
  `,
  selectedColorItem: css`
    outline: 2px solid ${COLOR.BASE_FOREGROUND};
  `,
  color: (color: string) => css`
    background-color: ${color};
  `,
  transparentColorItem: css`
    background-color: #fff;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: repeating-linear-gradient(45deg, transparent, transparent 5px, red 5px, red 10px);
      opacity: 0.5;
    }
  `
};
