import { COLOR, CONTAINER_WIDTH, FONT_SIZE, FONT_WEIGHT, GAP, GLOBAL_HEADER_HEIGHT, ITEM_HEIGHT_GAP, RADIUS, TRANSITION_SPEED } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';
import { cast } from '../Evaluator/cast';

export const containerType = {
  [ViewModel.LayoutType.FULL]: css('grid-template-columns: minmax(0, 1fr);'),
  [ViewModel.LayoutType.TWO]: css('grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);'),
  [ViewModel.LayoutType.TWO_ALT_1]: css('grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);'),
  [ViewModel.LayoutType.TWO_ALT_2]: css('grid-template-columns: minmax(0, 3fr) minmax(0, 7fr);'),
  [ViewModel.LayoutType.TWO_ALT_3]: css('grid-template-columns: minmax(0, 1fr) minmax(0, 300px);'),
  [ViewModel.LayoutType.TWO_ALT_4]: css('grid-template-columns: minmax(0, 300px) minmax(0, 1fr);'),
  [ViewModel.LayoutType.THREE]: css('grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr);'),
  [ViewModel.LayoutType.THREE_ALT_1]: css('grid-template-columns: minmax(0, 2fr) minmax(0, 6fr) minmax(0, 2fr);'),
  [ViewModel.LayoutType.FOUR]: css('grid-template-columns: minmax(0, 25fr) minmax(0, 25fr) minmax(0, 25fr) minmax(0, 25fr);'),
  [ViewModel.LayoutType.RESPONSIVE]: css(`grid-template-columns: repeat(auto-fit, ${CONTAINER_WIDTH.MIN}px);`),
  [ViewModel.LayoutType.VERTICAL_TAB]: css('flex: 1;')
};

export const getItemHeight = (config: string, top?: number) => {
  switch (config) {
    case ViewModel.LayoutHeightPreset.S:
      return '115px';
    case ViewModel.LayoutHeightPreset.M:
      return '540px';
    case ViewModel.LayoutHeightPreset.L:
      return '670px';
    case ViewModel.LayoutHeightPreset.DYNAMIC_1:
      return '25vh';
    case ViewModel.LayoutHeightPreset.DYNAMIC_2:
      return '33vh';
    case ViewModel.LayoutHeightPreset.DYNAMIC_3:
      return '50vh';
    case ViewModel.LayoutHeightPreset.DYNAMIC_4:
      return '66vh';
    case ViewModel.LayoutHeightPreset.DYNAMIC_5:
      return '75vh';
    case ViewModel.LayoutHeightPreset.DYNAMIC_6:
      return 'calc(100vh - 75px)';
    case ViewModel.LayoutHeightPreset.REMAINING: {
      const banner = document.querySelector('[data-testid="banner"]');
      const { clientHeight = 0 } = banner || {};
      const isBannerOnTop = banner?.getBoundingClientRect()?.top === 0;
      const bannerHeight = isBannerOnTop ? 0 : clientHeight;
      const calculatedTop = top || 0;
      const GAP_M = cast(ITEM_HEIGHT_GAP, 'number');
      const maxFitHeight = window.innerHeight - (bannerHeight + GAP_M + calculatedTop);
      return `${maxFitHeight}px`;
    }
    default:
      return config;
  }
};

export const getRuntimeType = ({
  width,
  childCount,
  maxWidth,
  type,
  isFullScreen
}: {
  width: number;
  childCount: number;
  maxWidth: number;
  type: ViewModel.LayoutType;
  isFullScreen: boolean;
}) => {
  if ([ViewModel.LayoutType.TAB, ViewModel.LayoutType.VERTICAL_TAB, ViewModel.LayoutType.RESPONSIVE].includes(type)) return type;

  const inferredType = (() => {
    if (childCount > 4 || isFullScreen) return ViewModel.LayoutType.FULL;
    if (Object.values(ViewModel.LayoutType).includes(type)) return type;
    if (childCount === 2) return ViewModel.LayoutType.TWO;
    if (childCount === 3) return ViewModel.LayoutType.THREE;
    if (childCount === 4) return ViewModel.LayoutType.FOUR;
    return ViewModel.LayoutType.FULL;
  })();

  if (width / childCount < maxWidth) {
    if (inferredType === ViewModel.LayoutType.FOUR) {
      return getRuntimeType({ width, childCount: Math.min(2, childCount), maxWidth, type: ViewModel.LayoutType.TWO, isFullScreen });
    }
    return ViewModel.LayoutType.FULL;
  }
  return inferredType;
};

export const styles = {
  container: css`
    display: grid;
    gap: ${GAP.M};
    overflow: auto;
    grid-auto-rows: max-content;
  `,
  bbContainer: css`
    width: 100%;
  `,
  content: css`
    display: flex;
    flex-direction: column;
  `,
  borderContainer: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border: 1px solid ${COLOR.NEUTRAL_4};
    border-radius: ${RADIUS.M};
    padding: ${GAP.M};
  `,
  colorContainer: css`
    border-radius: ${RADIUS.M};
    padding: ${GAP.M};
  `,
  openHeaderContainer: css`
    margin-bottom: ${GAP.S};
  `,
  headerContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${GAP.M};
  `,
  headerContent: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${GAP.S};
  `,
  headerDivision: css`
    border-bottom: 1px ${COLOR.NEUTRAL_3} solid;
    padding-bottom: ${GAP.S};
  `,
  header: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.NORMAL};
    margin-top: ${GAP.XXS};
    color: ${COLOR.NEUTRAL_2};
  `,
  column: css`
    max-width: 100%;
    overflow-x: auto;
    position: relative;
    :hover {
      > [data-fs-toggle] {
        opacity: 1;
      }
    }
  `,
  fsToggle: css`
    z-index: 1;
    position: absolute;
    transition: all ${TRANSITION_SPEED};
    opacity: 0;
    margin: ${GAP.S} ${GAP.XXS};
    top: 0;
    right: 0;
    cursor: pointer;
  `,
  fsColumn: css`
    position: fixed;
    top: ${GLOBAL_HEADER_HEIGHT};
    left: 0;
    width: 100%;
    height: calc(100% - ${GLOBAL_HEADER_HEIGHT});
    z-index: 11;
    overflow-y: auto;
    background: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.L};
  `,
  fsButton: css`
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${COLOR.BRAND_1};
    transition: all ${TRANSITION_SPEED};
  `,
  expand: css`
    transform: rotate(225deg);
  `,
  collapse: css`
    transform: rotate(45deg);
  `,
  hidden: css`
    display: none;
  `,
  blank: css`
    visibility: hidden;
  `,
  verticalTabContainer: css`
    flex: 1;
    display: flex;
    gap: ${GAP.M};
  `
};
