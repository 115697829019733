import type { InfoBlockType } from '../InfoBlock/types';
import type { LexicalEditor } from 'lexical';
import { $createParagraphNode, $getSelection } from 'lexical';
import { $createInfoBlockNode } from '../../nodes/InfoBlockNode';
import { $createExtendedTextNode } from '../../nodes/ExtendedTextNode';

export const formatInfoBlock = (editor: LexicalEditor, type: InfoBlockType) => {
  editor.update(() => {
    const selection = $getSelection();

    if (selection !== null) {
      const infoBlockNode = $createInfoBlockNode(type);
      const paragraphNode = $createParagraphNode();

      const spaceNode = $createExtendedTextNode(' ');

      selection.insertNodes([infoBlockNode, spaceNode]);
      infoBlockNode.getParent().insertAfter(paragraphNode);

      paragraphNode.select();
    }
  });
};
