import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS } from '@constants';
import { BREAKPOINT_SIZE_L, BREAKPOINT_SIZE_M } from '../../constants';
import { isMobile } from '@utils/isMobile';

export const styles = {
  toolbar: (isCollapsed, width) => css`
    display: flex;
    gap: ${width >= BREAKPOINT_SIZE_M ? GAP.M : GAP.S};
    background: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.XS};
    overflow: auto;
    height: 36px;
    position: sticky;
    top: 0;
    z-index: 2;
    border: ${isCollapsed ? 'none' : `1px solid ${COLOR.NEUTRAL_3}`};
    ${isMobile() ? mobileStyles(isCollapsed) : webStyles(isCollapsed)}
    color: ${COLOR.NEUTRAL_3};
  `,
  fileHiddenInput: css`
    display: none;
  `,
  hr: css`
    height: 1px;
    background-color: ${COLOR.NEUTRAL_3};
    border: none;
  `,
  blockOptionDropdown: width => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${width >= BREAKPOINT_SIZE_L ? '125px' : 'auto'};

    & p {
      font-weight: 400;
    }
  `
};

const webStyles = isCollapsed => css`
  border-top-left-radius: ${RADIUS.S};
  border-top-right-radius: ${RADIUS.S};
  ${isCollapsed && `border-bottom: 1px solid ${COLOR.NEUTRAL_3};`}
`;

const mobileStyles = isCollapsed => css`
  border-bottom-left-radius: ${RADIUS.S};
  border-bottom-right-radius: ${RADIUS.S};
  ${isCollapsed && `border-top: 1px solid ${COLOR.NEUTRAL_3};`}
`;
