import { create } from 'zustand';

interface PopoverState {
  currentPopoverId: string | null;
  openPopover: (popoverId: string) => void;
  closePopover: () => void;
  optionMenu: { currentOptionMenuId: string | null; openOptionMenu: (optionMenuId: string) => void; closeOptionMenu: () => void };
}

export const usePopover = create<PopoverState>(set => ({
  currentPopoverId: null,
  openPopover: (popoverId: string) => set(() => ({ currentPopoverId: popoverId })),
  closePopover: () => set(() => ({ currentPopoverId: null })),
  optionMenu: {
    currentOptionMenuId: null,
    openOptionMenu: (optionMenuId: string) => set(state => ({ optionMenu: { ...state.optionMenu, currentOptionMenuId: optionMenuId } })),
    closeOptionMenu: () => set(state => ({ optionMenu: { ...state.optionMenu, currentOptionMenuId: null } }))
  }
}));
