import type { GeneralModel } from '@cyferd/client-engine';
import { Translate, useTranslate } from '@cyferd/client-engine';

import { COLOR, FONT_SIZE } from '@constants';
import { CTA, CTAType } from '../CTA';
import { Icon } from '../Icon';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';
import { SelectDropdownType } from '../SelectDropdown/types';
import { useMemo } from 'react';

export interface DropdownOptionProps {
  type?: SelectDropdownType;
  testid?: string;
  image?: string;
  imageFill?: string;
  imageOutlined?: boolean;
  title?: string;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  value?: any;
  truncate?: boolean;
  disableHover?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  onClick?: (value: any) => void;
  onClose?: () => void;
  active?: boolean;
}

export const DropdownOption = ({
  type = SelectDropdownType.REGULAR,
  testid = 'dropdown-option',
  image,
  imageFill,
  imageOutlined,
  title,
  disableHover,
  description,
  value,
  color,
  onClick,
  disabled,
  highlight,
  truncate,
  onClose,
  active
}: DropdownOptionProps) => {
  const { translate } = useTranslate();

  const iconFill = useMemo(() => {
    if (imageFill) return imageFill;

    if (highlight) return COLOR.BASE_FOREGROUND;

    if (active) return COLOR.BRAND_1;

    return COLOR.NEUTRAL_1;
  }, [imageFill, highlight, active]);

  return (
    <CTA type={CTAType.SEEMLESS} width="full" onClick={typeof onClick === 'function' ? () => onClick(value) : undefined} testid={testid} disabled={disabled}>
      <div
        data-selector="dropdown-option"
        css={[
          styles.container,
          !!COLOR[color] && styles.containerLeftRoundCorners,
          highlight && styles.containerHighlighted,
          (disabled || disableHover) && styles.containerDisabled
        ]}
      >
        <div css={styles.infoContainer}>
          <div css={styles.colorBar} style={{ backgroundColor: COLOR[color] }}></div>
          {!!image && (
            <div css={styles.imageContainer}>
              <IconImage
                icon={image}
                title={translate(title)}
                iconProps={{
                  size: '20px',
                  fill: iconFill,
                  outlined: imageOutlined
                }}
                imageProps={{ css: { width: 20, height: 20, objectFit: 'cover', borderRadius: '100%' } }}
              />
            </div>
          )}
          <div css={styles.textContainer}>
            {!!title && (
              <p
                css={[
                  styles.title,
                  type !== SelectDropdownType.PAGINATION && highlight && styles.highlightedTitle,
                  truncate && styles.truncate,
                  active && styles.active
                ]}
              >
                <Translate>{title}</Translate>
              </p>
            )}
            {!!description && (
              <p css={[styles.description, highlight && styles.highlightedDescription, truncate && styles.truncate]}>
                <Translate>{description}</Translate>
              </p>
            )}
          </div>
        </div>
        {!!onClose && !disabled && (
          <PreventClickPropagation>
            <div onClick={onClose} data-testid={`${testid}-close`} css={styles.closeContainer}>
              <Icon name="close" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XM} />
            </div>
          </PreventClickPropagation>
        )}
      </div>
    </CTA>
  );
};
