import type { ComponentProps, ReactNode } from 'react';
import { memo, useCallback } from 'react';

import { COLOR, FONT_SIZE } from '@constants';
import { Icon } from '../Icon';
import { getStyles } from './styles';
import { CTA, CTAType } from '../CTA';

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'information'
}

export interface ToastProps {
  id: string;
  title?: ReactNode;
  message?: string;
  status: ToastStatus;
  onClose: (id: string) => void;
  testid?: string;
  ctaProps?: Omit<ComponentProps<typeof CTA>, 'type' | 'color'>;
}

export const toastWidth = '350px';

export const Toast = memo(({ id, title, message, onClose, status, testid = 'toast', ctaProps }: ToastProps) => {
  const { styles, iconName, iconColor } = (() => {
    switch (status) {
      case ToastStatus.ERROR:
        return { styles: getStyles('RD_1', toastWidth), iconName: 'cancel', iconColor: COLOR.RD_1 };
      case ToastStatus.SUCCESS:
        return { styles: getStyles('GN_7', toastWidth), iconName: 'check_circle', iconColor: COLOR.GN_7 };
      case ToastStatus.WARN:
        return { styles: getStyles('OE_3', toastWidth), iconName: 'warning', iconColor: COLOR.OE_3 };
      case ToastStatus.INFO:
      default:
        return { styles: getStyles('BE_9', toastWidth), iconName: 'info', iconColor: COLOR.BE_9 };
    }
  })();

  const onInternalClose = useCallback(() => onClose(id), [onClose, id]);
  return (
    <div data-testid={`${testid}-container`} role="alert" css={styles.container}>
      <div data-testid={`${testid}-status-${status}`} css={styles.content}>
        {!!title && (
          <header css={styles.header}>
            <p css={styles.title} data-testid={`${testid}-title`}>
              <span css={styles.iconTitle}>
                <Icon size="25px" name={iconName as ComponentProps<typeof Icon>['name']} fill={iconColor} title="toast header close" />
              </span>
              {title}
            </p>
            <div css={styles.closeBtn}>
              <Icon name="close" size={FONT_SIZE.L} fill={COLOR.NEUTRAL_1} onClick={onInternalClose} testid={`${testid}-close-btn`} />
            </div>
          </header>
        )}
        {!!message && (
          <p data-testid={`${testid}-message`} css={styles.message}>
            {message}
          </p>
        )}
        {!!ctaProps && (
          <div css={styles.cta}>
            <CTA {...ctaProps} type={CTAType.LINK} />
          </div>
        )}
      </div>
    </div>
  );
});

Toast.displayName = 'Toast';
