import { FileInput } from '@components/elements/FileInput';
import { $getNodeByKey } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isFileNode } from '../../nodes/FileNode';
import { useContext } from 'react';
import { ClientEngineContext } from '@cyferd/client-engine';
import type { FileObject } from '../../types';
import type { NodeKey } from 'lexical/LexicalNode';
import { useFileManagement } from '../../hooks/useFileManagement';
import { style } from '@components/elements/RichTextEditor/RichEditorComposition/components/FileBlock/style';

interface FileBlockProps {
  nodeKey: NodeKey;
  file: FileObject;
}

export const FileBlock = ({ nodeKey, file }: FileBlockProps) => {
  const [editor] = useLexicalComposerContext();

  const { onUploadFile, onDownloadFile } = useFileManagement();

  const { platformConfig } = useContext(ClientEngineContext);

  const handleDelete = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isFileNode(node)) {
        node.remove();
      }
    });
  };

  const setFile = (file: FileObject) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isFileNode(node)) {
        node.setFile(file, `${platformConfig?.fileUploadUrl}/${file?.id}`);
      }
    });
  };

  return (
    <div css={style.fileInputWrapper}>
      <FileInput
        value={file}
        required={false}
        disabled={!editor.isEditable()}
        id={'file-input'}
        onChange={setFile}
        displayNamePath={['']}
        hasOptionList={true}
        multiple={false}
        onGetFileRequest={onUploadFile}
        onDownloadFile={onDownloadFile}
        onRemoveCallback={handleDelete}
        // other props are irrelevant here
        {...({} as any)}
      />
    </div>
  );
};
