import { ClientEngineContext } from '@cyferd/client-engine';
import { hasFlag, logger } from '@utils';
import { useContext } from 'react';

interface useFeatureFlagProps {
  flag: string;
}

export const useFeatureFlag = ({ flag }: useFeatureFlagProps) => {
  const clientEngineContext = useContext(ClientEngineContext);

  // to handle possible absence of context provider
  const user = clientEngineContext?.useUserSelector?.();

  if (!clientEngineContext || !user) {
    logger.warn('ClientEngineContext is not available. Returning default "false" value.');
    return false;
  }

  return hasFlag(user, flag);
};
