import { css } from '@emotion/react';
import { GAP } from '@constants';

export const styleHelpers = {
  flexBetween: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexStart: (gap = GAP.XS) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${gap};
  `,
  flex1: css`
    flex: 1;
  `,
  cursorPointer: css`
    cursor: pointer;
  `
};
