import { COLOR, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  headerContainer: css`
    width: 100%;
    z-index: 5;
    justify-content: center;
  `,
  headerInnerContainer: css`
    position: sticky;
    top: 73px;
    padding: 0 ${GAP.S};
    width: 100%;
    z-index: 5;
    background-color: ${COLOR.NEUTRAL_6};
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 8px;
      background-color: ${COLOR.NEUTRAL_6};
      top: -8px;
    }
  `
};
