import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { debounce } from '@cyferd/client-engine';
import { useEffect } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot } from 'lexical';

type ReturnType = 'html' | 'text';

interface OnChangePluginProps {
  value: string;
  onChange: (value) => void;
  returnType?: ReturnType;
}

export const OnChangePlugin = ({ value, onChange, returnType = 'html' }: OnChangePluginProps) => {
  const [editor] = useLexicalComposerContext();

  const debouncedOnChange = debounce(content => onChange?.(content), 500);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        if (returnType === 'html') {
          const htmlString = $generateHtmlFromNodes(editor, null);

          if (htmlString !== value) debouncedOnChange(htmlString);
        } else if (returnType === 'text') {
          const updatedText = editor.getEditorState().read(() => {
            return $getRoot().getTextContent();
          });

          debouncedOnChange(updatedText);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, debouncedOnChange]);

  return null;
};
