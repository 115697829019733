import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS, SHADOW, Z_INDEX } from '@constants';

export const styles = {
  selectorWrapper: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    z-index: ${Z_INDEX.DROPDOWN};
    padding: ${GAP.M} ${GAP.S} ${GAP.XXXS} ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    max-height: 270px;
    overflow: auto;
    max-width: 190px;
  `,
  hr: css`
    height: 1px;
    background-color: ${COLOR.NEUTRAL_3};
    border: none;
  `,
  deleteLabel: css`
    color: ${COLOR.HC_5};
  `
};
