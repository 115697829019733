import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/css';

export const getStyles = (nestedLevel: number, color: GeneralModel.Color.ThemeColor) => ({
  container: css`
    display: flex;
    gap: ${GAP.XS};
    margin-bottom: ${GAP.XXS};
  `,
  listItem: css`
    cursor: pointer;
    padding: ${GAP.XS} 0;
    border-radius: ${RADIUS.S};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    gap: ${GAP.XXS};
    transition: background-color ${TRANSITION_SPEED};
    flex: 1;
    width: 100%;
    padding-left: calc(${GAP.M} * ${nestedLevel + 1});
  `,
  colorBar: css`
    // background-color: ${COLOR[color]}; // hidden feature until a decision gets made
    // border-radius: ${RADIUS.S};
    // width: ${GAP.XS};
  `,
  listItemInnerContainer: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 0;
  `,
  listItemText: css`
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: 13px;
    line-height: normal;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  listItemTitle: css`
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  strongTitle: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
    line-height: normal;
  `,
  activeListItem: css`
    background-color: ${COLOR.BRAND_1}!important;
  `,
  highlightedListItem: css`
    background-color: ${SECONDARY_COLOR.BRAND_2}!important;
  `,
  listItemTextActive: css`
    color: ${COLOR.BASE_BACKGROUND}!important;
  `,
  indicator: css`
    display: inline-block;
    margin-right: ${GAP.S};
  `,
  overriden: css`
    display: flex;
    justify-content: flex-end;
    flex: 1;
  `
});
