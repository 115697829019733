import { css } from '@emotion/react';

import { COLOR, GAP } from '@constants';

export const styles = {
  inputLabelFocus: css`
    color: ${COLOR.BRAND_1};
  `,
  darkScheme: css`
    color-scheme: dark;
  `,
  wrappedContent: css`
    flex: 1;
    display: flex;
    align-items: center;

    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='week'] {
      font-feature-settings: normal;
      font-variant-numeric: normal;
    }
  `,
  wrappedInput: css`
    width: 100%;
  `,
  wrappedTextarea: css`
    padding-top: ${GAP.XXS};
    width: 100%;
    border: 0;
    height: auto;
    resize: none;
    display: block;
    line-height: normal !important;
    min-height: 21px;
  `
};
