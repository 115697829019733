import { get } from 'lodash';
import type { ComponentProps } from 'react';

import { EvaluatorModel, GeneralModel, createUUID, getFormulaKey, isObject, noop, prepareTermForReg, removeDeep, replaceDeep } from '@cyferd/client-engine';

import type { Chip } from '@components/elements/Chips/Chip';
import { FormulaInputType, getFormulaInputType } from './getFormulaInputType';
import type { DropResult } from '@hello-pangea/dnd';
import { logger } from '@utils/logger';
import type { TypeToCast } from './cast';
import { cast, getType } from './cast';
import { getDataTypeLabel } from '@constants';

export const EVALUATOR_FIELD_ID = 'evaluator-field-id';
export const EVALUATOR_PORTAL_ID = 'evaluator-modal-container';

export const UNIQUE_PATH_PREFIX = `${createUUID()}::::`;
export const getCleanPath = (path: string) => path.replace(new RegExp(`^.*${UNIQUE_PATH_PREFIX}`), '');

export const getHandlingEmptyPath = (value: any, path: string) => (path ? get(value, path) : value);

export enum EVALUATOR_TAB {
  INPUTS = 'a',
  PLAYGROUND = 'b'
}

export interface FormulaInputRow {
  id: string;
  label: string;
  description?: string;
  formulaType: FormulaInputType;
  template: any;
  format?: GeneralModel.JSONSchemaFormat;
  type: GeneralModel.JSONSchema['type'];
  groupName?: string;
  isAlias?: boolean;
  keywords?: string[];
  input?: GeneralModel.JSONSchema;
  output?: GeneralModel.JSONSchema;
  value?: any;
}

const formulaCategoryLabels = ['Text', 'Number', 'List', 'Data', 'Date & Time', 'Conversion', 'Logic & Condition', 'More'] as const;
export type FormulaCategoryLabel = (typeof formulaCategoryLabels)[number];

/* istanbul ignore next line | @todo */
const getPlaceholderFormulaFromSchema = (input: GeneralModel.JSONSchema) => {
  if (input.type === 'array') {
    return Array.from({ length: input?.minItems || 1 })
      .map((_, index) => input.prefixItems?.[index] || input.items)
      .filter(isObject)
      .map(i => getPlaceholderFormulaFromSchema(i));
  }
  if (input.type === 'object') {
    return Object.fromEntries(Object.entries(input.properties || {}).map(([k, prop]) => [k, getPlaceholderFormulaFromSchema(prop)]));
  }

  if (![null, undefined].includes(input.default)) return input.default;
  if (input.type === 'any') return 'any';
  if (input.type === 'string') return 'string';
  if (input.type === 'number') return input.minimum ?? 0;
  if (input.type === 'boolean') return true;
  return null;
};

export const formulaMap: { [id: string]: FormulaInputRow } = EvaluatorModel.formulaList
  .map(key => EvaluatorModel.formulaMap[key])
  .filter(Boolean)
  .map(config => {
    const { label, input, output, key, category, description, alias, keywords } = config;
    return {
      id: key,
      label,
      input,
      output,
      description,
      formulaType: FormulaInputType.FORMULA,
      type: output?.type,
      groupName: `Fx ${category}`,
      isAlias: alias,
      keywords,
      template: {
        [key]: getPlaceholderFormulaFromSchema(input)
      }
    } as FormulaInputRow;
  })
  .sort((a, b) => {
    const aIndex = formulaCategoryLabels.indexOf(a.groupName as FormulaCategoryLabel);
    const bIndex = formulaCategoryLabels.indexOf(b.groupName as FormulaCategoryLabel);
    return String(aIndex).localeCompare(String(bIndex));
  })
  .reduce((total, config) => ({ ...total, [config.id]: config }), {});

const formatReference = (ref: string) => ref?.split?.('.')?.join?.(' ');

export const getFormulaInputRowItem = (config: {
  id?: string;
  prefix?: string;
  base?: string;
  path?: string;
  label?: string;
  type?: GeneralModel.JSONSchema['type'];
  format?: GeneralModel.JSONSchemaFormat;
  formulaType?: FormulaInputType;
  groupName?: string;
  template?: any;
  description?: string;
  isAlias?: boolean;
  keywords?: string[];
}): FormulaInputRow => {
  const { id, template, prefix, base, path = '', label, format, description, formulaType, type, groupName, isAlias, keywords = [] } = config;
  return {
    id: id || [groupName, label, prefix, base, path, type, formulaType].join('-'),
    label: label ?? ([formatReference(base), formatReference(path)].filter(Boolean).join(' ') || String(template)),
    formulaType: formulaType || FormulaInputType.REFERENCE,
    template: template ?? `{{${base}${path ? /* istanbul ignore next */ `.${path}` : ''}}}`,
    type: type || GeneralModel.formatToTypeMap[format] || 'any',
    output: { format },
    description,
    groupName,
    isAlias: !!isAlias,
    keywords
  };
};

const findAndRemoveDeep = (sample: symbol, value: any) => {
  if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value)
        .filter(([_, level]) => level !== sample)
        .map(([key, level]) => [key, findAndRemoveDeep(sample, level)])
    );
  }
  if (Array.isArray(value)) return value.filter(level => level !== sample).map(level => findAndRemoveDeep(sample, level));
  return value;
};

export const fxList: FormulaInputRow[] = Object.values(formulaMap);

export const defaultInputList: FormulaInputRow[] = [
  {
    id: `fixed-field-${FormulaInputType.STRING}`,
    groupName: 'Basic',
    label: 'String',
    type: 'string',
    description: 'An empty string',
    formulaType: FormulaInputType.STRING,
    format: GeneralModel.JSONSchemaFormat.TEXT,
    template: '',
    output: { format: GeneralModel.JSONSchemaFormat.TEXT }
  },
  {
    id: `fixed-field-${FormulaInputType.NUMBER}`,
    groupName: 'Basic',
    label: 'Number',
    type: 'number',
    formulaType: FormulaInputType.NUMBER,
    format: GeneralModel.JSONSchemaFormat.NUMBER,
    template: 0,
    output: { format: GeneralModel.JSONSchemaFormat.NUMBER }
  },
  {
    id: `fixed-field-${FormulaInputType.BOOLEAN}`,
    groupName: 'Basic',
    label: 'Boolean',
    type: 'boolean',
    formulaType: FormulaInputType.BOOLEAN,
    format: GeneralModel.JSONSchemaFormat.CHECKBOX,
    template: true,
    output: { format: GeneralModel.JSONSchemaFormat.CHECKBOX }
  },
  {
    id: `fixed-field-${FormulaInputType.OBJECT}`,
    groupName: 'Basic',
    label: 'JSON (object)',
    description: 'An empty object',
    type: 'object',
    formulaType: FormulaInputType.OBJECT,
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    template: {},
    output: { format: GeneralModel.JSONSchemaFormat.JSON }
  },
  {
    id: `fixed-field-${FormulaInputType.ARRAY}`,
    groupName: 'Basic',
    label: 'JSON (array)',
    description: 'An empty array',
    type: 'array',
    formulaType: FormulaInputType.ARRAY,
    format: GeneralModel.JSONSchemaFormat.ARRAY,
    template: [],
    output: { format: GeneralModel.JSONSchemaFormat.ARRAY }
  },
  {
    id: `fixed-field-${FormulaInputType.NULL}`,
    groupName: 'Basic',
    label: 'Null',
    type: 'null',
    formulaType: FormulaInputType.NULL,
    format: GeneralModel.JSONSchemaFormat.EVALUATION,
    template: null,
    output: {}
  }
];

export const getRawType = (item: any): GeneralModel.JSONSchema['type'] => {
  if (Array.isArray(item)) return 'array';
  if (item === null) return 'null';
  if (item === undefined) return 'any';
  return typeof item as GeneralModel.JSONSchema['type'];
};

export const getFormulaChipList = (formulaValue: object): ComponentProps<typeof Chip>[] => {
  return Object.entries(formulaValue || {})
    .map(([key, value]) => ({ schema: EvaluatorModel.formulaOptionsSchema.properties[key], value }))
    .filter(({ schema, value }) => !!schema && !(schema.type === 'boolean' && [null, undefined, false].includes(value)))
    .sort((a, b) => a.schema.metadata?.detailOrder - b.schema.metadata?.detailOrder)
    .map(({ schema, value }) => ({
      id: schema.key,
      title: schema.label,
      description: schema.type === 'boolean' ? schema.description : JSON.stringify(value, null, 4),
      color: 'NEUTRAL_3',
      icon: undefined,
      active: false,
      disabled: false,
      showCheck: false,
      compact: true,
      onClick: noop
    }));
};

export interface FlatFormulaRow {
  path: string;
  key: string;
  depth: number;
  parentConfig: FlatFormulaRow;
  closingDropzones: string[];
  type: FormulaInputType;
  valueType: GeneralModel.JSONSchema['type'];
  value: any;
  length: number;
  formulaKey: string;
  formulaInputRow: FormulaInputRow;
  index: number;
  ddId: string;
}

export const getReferenceLabel = (value: string, referenceLabelMap: Record<string, string>): string => {
  if (!Object.keys(referenceLabelMap || {}).length) return JSON.stringify(value);
  /** replaces all formulas in a single string with their labels and wraps them in [] */
  const result = value.replace(new RegExp(Object.keys(referenceLabelMap).map(prepareTermForReg).join('|'), 'g'), match => `[${referenceLabelMap[match]}]`);
  /** keeps "string quotes" for strings that are not a single reference */
  return !/^[^[\]]*\[[^[\]]*\][^[\]]*$/.test(result) || !/^\[.*\]$/.test(result) ? JSON.stringify(result) : result;
};

const getReferenceLabelMap = (inputList: FormulaInputRow[]): Record<string, string> => {
  return inputList?.reduce((total, curr) => {
    if (typeof curr.template !== 'string' || curr.formulaType !== FormulaInputType.REFERENCE) return total;
    return { ...total, [curr.template]: curr.label };
  }, {});
};

const getInputConfig = ({
  value,
  formulaKey,
  type,
  inputList,
  referenceLabelMap
}: {
  value: any;
  formulaKey: string;
  type: FormulaInputType;
  inputList: FormulaInputRow[];
  referenceLabelMap: Record<string, string>;
}) => {
  const valueIsString = typeof value === 'string';
  const valueIsFormula = type === FormulaInputType.FORMULA;
  const valueIsArray = Array.isArray(value);
  const needsNewLabel = valueIsString && type === FormulaInputType.REFERENCE;
  const formulaConfig = formulaMap[formulaKey];
  const valueIsObject = isObject(value);

  if (valueIsFormula) return { ...formulaMap[formulaKey], value: value };
  const foundConfig = inputList?.find?.(i => i.template === value);
  const parsedType = getRawType(value);
  return !['String', 'Number', 'Boolean', null, undefined].includes(foundConfig?.label)
    ? { ...foundConfig, label: needsNewLabel ? getReferenceLabel(value, referenceLabelMap) : foundConfig.label }
    : getFormulaInputRowItem({
        formulaType: formulaConfig?.formulaType || type,
        type: parsedType,
        format: GeneralModel.typeToFormatMap[parsedType]?.[0],
        label: (() => {
          if (valueIsArray) return getDataTypeLabel(GeneralModel.JSONSchemaFormat.ARRAY);
          if (valueIsObject) return getDataTypeLabel(GeneralModel.JSONSchemaFormat.OBJECT);
          if (needsNewLabel) return getReferenceLabel(value, referenceLabelMap);
          return JSON.stringify(value);
        })(),
        template: value
      });
};

const getFlatFormulaInternal = ({
  value,
  pathList,
  inputList,
  parentLevelConfig,
  referenceLabelMap,
  parentClosingDropzones
}: {
  value: any;
  pathList: string[];
  inputList: FormulaInputRow[];
  parentLevelConfig?: FlatFormulaRow;
  referenceLabelMap: Record<string, string>;
  parentClosingDropzones: string[];
}): FlatFormulaRow[] => {
  const type = getFormulaInputType(value);
  const valueIsFormula = type === FormulaInputType.FORMULA;
  const valueIsObject = isObject(value);
  const valueIsArray = Array.isArray(value);
  const formulaKey = valueIsFormula ? getFormulaKey(value) : null;
  const isArrayOrFormula = [FormulaInputType.FORMULA, FormulaInputType.ARRAY].includes(type);
  const length = !isArrayOrFormula ? null : Object.keys(value[formulaKey] || value).length;
  const path = pathList.join('.');
  const key = String(pathList[pathList.length - 1] || '');
  const closingDropzones = !isArrayOrFormula && Number(key) + 1 === parentLevelConfig?.length ? parentClosingDropzones : [];

  const inputConfig = getInputConfig({ value, formulaKey, type, inputList, referenceLabelMap });

  const config: FlatFormulaRow = {
    key,
    path,
    depth: parentLevelConfig?.depth + 1 || 0,
    type,
    parentConfig: parentLevelConfig,
    length,
    valueType: inputConfig.type,
    value,
    formulaKey,
    formulaInputRow: inputConfig,
    closingDropzones,
    index: null, // filled in later. only needed for the d&d library, but not consumed anywhere in the app
    ddId: `${path}${UNIQUE_PATH_PREFIX}${(() => {
      if (valueIsFormula) return [...pathList, formulaKey, 0].join('.');
      if (valueIsArray || valueIsObject) return [...pathList, 0].join('.');
      if ([FormulaInputType.ARRAY, FormulaInputType.FORMULA].includes(parentLevelConfig?.type)) {
        return [...pathList.slice(0, pathList.length - 1), Number(key) + 1].join('.');
      }
      return path;
    })()}`
  };

  const valueToLoop = (() => {
    if (valueIsFormula) return value[formulaKey];
    if (valueIsObject || valueIsArray) return value;
  })();
  const shouldLoop = [valueIsFormula, valueIsObject, valueIsArray].some(Boolean);

  return [
    config,
    !shouldLoop
      ? []
      : Object.entries(valueToLoop)
          .map(([k, v], index, list) => {
            const basePath = [...pathList, valueIsFormula ? config.formulaKey : ''].filter(Boolean).flat();
            return getFlatFormulaInternal({
              value: v,
              pathList: [...pathList, valueIsFormula ? [config.formulaKey, k] : k].flat(),
              inputList,
              parentLevelConfig: config,
              referenceLabelMap,
              parentClosingDropzones: [!!length && index === list.length - 1 && [...basePath, Number(k) + 1].join('.'), ...parentClosingDropzones].filter(
                Boolean
              )
            });
          })
          .flat()
  ].flat();
};

export const getFlatFormula = (value: any, inputList: FormulaInputRow[]): FlatFormulaRow[] => {
  const referenceLabelMap = getReferenceLabelMap(inputList);
  const flatFormula = getFlatFormulaInternal({ value, pathList: [], inputList, referenceLabelMap, parentClosingDropzones: [] })
    .filter(r => r.value !== undefined)
    .map((r, index) => ({ ...r, index }));

  const flatFormulaWithoutRedundantClosing = [...flatFormula].reverse().reduce(
    (total, curr) => {
      return {
        usedClosingPaths: { ...total.usedClosingPaths, ...Object.values(curr.closingDropzones).reduce((t, c) => ({ ...t, [c]: true }), {}) },
        rows: [{ ...curr, closingDropzones: curr.closingDropzones.filter(p => !total.usedClosingPaths[p]) }, ...total.rows]
      };
    },
    { rows: [], usedClosingPaths: {} } as { rows: FlatFormulaRow[]; usedClosingPaths: Record<string, boolean> }
  ).rows;

  return flatFormulaWithoutRedundantClosing;
};

const splitParentAndTargetPaths = (path: string) => {
  const safePath = getCleanPath(path);
  const parentTargetPath = safePath.substring(0, safePath.lastIndexOf('.'));
  const targetKey = safePath.substring(safePath.lastIndexOf('.') + 1, safePath.length);
  return { parentTargetPath, targetKey };
};

export const getNewKey = (obj: object): string => `key_${Object.keys(obj).length + 1}`;

export const getStateAfterDrop = (currentState: any, result: Pick<DropResult, 'draggableId' | 'destination'>) => {
  try {
    const state = escapeKeys(currentState);
    if (!result?.destination) return state;
    const safeSourcePath = getCleanPath(result.draggableId);
    const safeTargetPath = getCleanPath(result.destination.droppableId);
    const { parentTargetPath, targetKey } = splitParentAndTargetPaths(safeTargetPath);
    const placeholder = Symbol('placeholder');
    const $source = getHandlingEmptyPath(state, safeSourcePath);
    const valueWithPlaceholder = replaceDeep(state, placeholder, safeSourcePath);
    const $parent = getHandlingEmptyPath(valueWithPlaceholder, parentTargetPath);

    const elementToReplaceInParent = (() => {
      if (isObject($parent)) {
        const oldObjectKey = safeSourcePath.slice(safeSourcePath.includes('.') ? safeSourcePath.lastIndexOf('.') : 0, safeSourcePath.length).replace(/^\./, '');
        const newObjectKey = !isNaN(Number(oldObjectKey)) ? getNewKey($parent) : oldObjectKey;
        return { ...$parent, [newObjectKey]: $source };
      }
      if (Array.isArray($parent)) {
        const newArrayIndex = Number(targetKey);
        return [...$parent.slice(0, newArrayIndex), $source, ...$parent.slice(newArrayIndex, $parent.length)];
      }
    })();

    const valueWithTargetPlaced = replaceDeep(valueWithPlaceholder, elementToReplaceInParent, parentTargetPath);
    const newState = unescapeKeys(findAndRemoveDeep(placeholder, valueWithTargetPlaced));
    return newState;
  } catch (error) /* istanbul ignore next */ {
    logger.error('drag & drop error', error);
    return currentState;
  }
};

export const OPTION_PREFIX = `${createUUID()}>>>>`;

export const getStateAfterNew = ({ state, result, option, label }: { state: any; result: DropResult; option: FormulaInputRow; label: string }) => {
  const tempRootKey = 'root';

  /* istanbul ignore next line | replace the value when it's primitive */
  if ([null, undefined].includes(state) || typeof state !== 'object') return option?.template;

  /** new cammelCase keys */
  const optionKey = label?.replace?.(/\s(.)/g, /* istanbul ignore next */ match => match.charAt(1).toUpperCase());
  const tempValue = { [OPTION_PREFIX]: { [optionKey]: option?.template }, [tempRootKey]: state };
  const tempResult: DropResult = {
    ...result,
    draggableId: `${UNIQUE_PATH_PREFIX}${OPTION_PREFIX}.${optionKey}`,
    destination: {
      ...result.destination,
      droppableId: `${UNIQUE_PATH_PREFIX}${tempRootKey}.${getCleanPath(result.destination.droppableId)}`
    }
  };
  return getStateAfterDrop(tempValue, tempResult)[tempRootKey];
};

export const getStateAfterDuplicate = (state: any, pathToDuplicate: string) => {
  const { parentTargetPath } = splitParentAndTargetPaths(pathToDuplicate);
  const $source = getHandlingEmptyPath(state, pathToDuplicate);
  const $parent = getHandlingEmptyPath(state, parentTargetPath);
  const label = getNewKey($parent);

  return getStateAfterNew({
    state,
    result: { draggableId: 'irrelevant', destination: { droppableId: pathToDuplicate } } as any,
    option: getFormulaInputRowItem({ label, template: $source }),
    label
  });
};

export const scrollToElement = (path: string) => {
  setTimeout(() => document.getElementById(path)?.scrollIntoView?.(false));
};

export const getBelowPath = (path: string): string => {
  const cleanPath = getCleanPath(path);
  const { parentTargetPath, targetKey } = splitParentAndTargetPaths(cleanPath);
  /* istanbul ignore if | this is only for safety. it should never happen */
  if (!parentTargetPath) return null;
  return [`${UNIQUE_PATH_PREFIX}${parentTargetPath}`, Number(targetKey) + 1].join('.');
};

export const getStateAfterUnwrap = (state: any, pathToUnwrap: string) => {
  if (!pathToUnwrap && [Array.isArray(state), isObject(state)].some(Boolean)) {
    const firstKey = Object.keys(state)[0];
    const isFormula = !!EvaluatorModel.formulaMap[firstKey] && Array.isArray(state[firstKey]);
    const firstElement = isFormula ? state[firstKey][0] : state[firstKey];
    return firstElement;
  }
  const { parentTargetPath, targetKey } = splitParentAndTargetPaths(pathToUnwrap);
  const $parent = getHandlingEmptyPath(state, parentTargetPath);
  const $element = (() => {
    const $base = getHandlingEmptyPath(state, pathToUnwrap);
    const formulaKey = getFormulaKey($base);
    return formulaKey ? $base[formulaKey] : $base;
  })();
  const newParent = (() => {
    if (Array.isArray($parent)) {
      const index = Number(targetKey);
      return [...$parent.slice(0, index), ...Object.values($element), ...$parent.slice(index + 1, $parent.length)];
    }
    if (isObject($parent)) {
      return {
        ...removeDeep($parent, targetKey),
        ...(isObject($element) ? $element : Object.fromEntries(Object.entries($element).map(([k, v]) => [`${targetKey}_${Number(k) + 1}`, v])))
      };
    }
    return $parent;
  })();
  return replaceDeep(state, newParent, parentTargetPath);
};

/* istanbul ignore next line | @todo */
export const getStateAfterDeleteContent = (state: any, path: string) => {
  const $element = getHandlingEmptyPath(state, path);
  const replacement = (() => {
    switch (getType($element)) {
      case 'array':
        return [];
      case 'object':
        return {};
      case FormulaInputType.FORMULA:
        return { ...$element, [getFormulaKey($element)]: [] };
    }
  })();

  return replaceDeep(state, replacement, path);
};

export interface InlineFormulaConfig {
  key: string;
  formulaInputRow: FormulaInputRow;
  content: InlineFormulaConfig[];
}

const getInlineFormulaConfigInternal = ({
  key,
  value,
  maxDepth,
  maxElements,
  inputList,
  referenceLabelMap,
  currenthDepth,
  currentElements
}: Parameters<typeof getInlineFormulaConfig>[0] & {
  key: string;
  referenceLabelMap: Record<string, string>;
  currenthDepth: number;
  currentElements: number;
}): InlineFormulaConfig => {
  const type = getFormulaInputType(value);
  const valueIsFormula = type === FormulaInputType.FORMULA;
  const valueIsObject = isObject(value);
  const valueIsArray = Array.isArray(value);
  const formulaKey = valueIsFormula ? getFormulaKey(value) : null;

  const formulaInputRow = getInputConfig({ value, formulaKey, type, inputList, referenceLabelMap });

  const valueToLoop = (() => {
    if (valueIsFormula) return value[formulaKey];
    if (valueIsObject || valueIsArray) return value;
  })();
  const shouldLoop = [valueIsFormula, valueIsObject, valueIsArray].some(Boolean);
  return {
    key,
    formulaInputRow,
    content: !shouldLoop
      ? []
      : Object.entries(valueToLoop)
          .filter((_, i) => currenthDepth + 1 <= maxDepth && currentElements + i + 1 <= maxElements)
          .map(([k, v], i) => {
            const nextDepth = currenthDepth + 1;
            const nextElements = currentElements + i + 1;
            if (nextDepth === maxDepth || nextElements === maxElements)
              return {
                key: k,
                formulaInputRow: getFormulaInputRowItem({ formulaType: FormulaInputType.STRING, type: 'string', label: '...', template: '...' }),
                content: []
              };
            return getInlineFormulaConfigInternal({
              key: k,
              value: v,
              maxDepth,
              maxElements,
              inputList,
              referenceLabelMap,
              currenthDepth: nextDepth,
              currentElements: nextElements
            });
          })
  };
};

export const getInlineFormulaConfig = ({
  value,
  maxDepth = Infinity,
  maxElements = Infinity,
  inputList
}: {
  value: any;
  maxDepth?: number;
  maxElements?: number;
  inputList: FormulaInputRow[];
}): InlineFormulaConfig => {
  if (value === undefined) return null;
  const referenceLabelMap = getReferenceLabelMap(inputList);
  return getInlineFormulaConfigInternal({ key: '', value, maxDepth, maxElements, inputList, referenceLabelMap, currenthDepth: 0, currentElements: 0 });
};

export const getStateAfterCast = (state: any, pathToCast: string, typeToCast: TypeToCast) => {
  const $element = getHandlingEmptyPath(state, pathToCast);
  const newValue = cast($element, typeToCast);
  return replaceDeep(state, newValue, pathToCast);
};

export const REPLACEMENT_CHAR = `${createUUID()}::>>`;

export const conditionalDotReplacement = (value: string, format: 'escape' | 'unescape') => {
  return format === 'escape' ? value?.replace(/\./g, REPLACEMENT_CHAR) : value?.replace(new RegExp(REPLACEMENT_CHAR, 'g'), '.');
};

export const hasCharInKeys = (obj: any, char: string): boolean => {
  if (isObject(obj)) {
    return Object.keys(obj).some(key => key.includes(char)) || Object.values(obj).some(value => hasCharInKeys(value, char));
  }

  if (Array.isArray(obj)) return obj.some(item => hasCharInKeys(item, char));

  return false;
};

export const escapeKeys = (obj: Record<string, any>): Record<string, any> => {
  if (!hasCharInKeys(obj, '.')) return obj;

  if (Array.isArray(obj)) {
    return obj.map(item => escapeKeys(item));
  }

  return Object.keys(obj).reduce((escapedObj, key) => {
    const escapedKey = conditionalDotReplacement(key, 'escape');
    const value = obj[key];

    if (isObject(value) || Array.isArray(value)) return { ...escapedObj, [escapedKey]: escapeKeys(value) };
    return { ...escapedObj, [escapedKey]: value };
  }, {});
};

export const unescapeKeys = (obj: Record<string, any>): Record<string, any> => {
  if (!hasCharInKeys(obj, REPLACEMENT_CHAR)) return obj;

  if (Array.isArray(obj)) return obj.map(item => unescapeKeys(item));

  return Object.keys(obj).reduce((unescapedObj, key) => {
    const unescapedKey = conditionalDotReplacement(key, 'unescape');
    const value = obj[key];

    if (isObject(value) || Array.isArray(value)) return { ...unescapedObj, [unescapedKey]: unescapeKeys(value) };
    return { ...unescapedObj, [unescapedKey]: value };
  }, {});
};

export const shouldAllowFormula = (global?: boolean, local?: boolean) => {
  return !!((global && local !== false) || local);
};
