import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $getRoot, $insertNodes } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';

// TODO: think of better naming. Now it is triggered not only initialization
export const InitializerPlugin = ({ value, isReadOnly, modalOpen }) => {
  const [editor] = useLexicalComposerContext();

  // is only for read only editor instances to always have freshest value
  useEffect(() => {
    if (!isReadOnly) return;

    editor.update(
      () => {
        onEditorUpdate(value, editor);
      },
      { discrete: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isReadOnly) return;

    editor.update(
      () => {
        onEditorUpdate(value, editor);
      },
      { discrete: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return null;
};

const onEditorUpdate = (value, editor) => {
  const root = $getRoot();

  if (!value) {
    root.clear();
    return;
  }

  const currentValue = $generateHtmlFromNodes(editor, null);

  if (value === currentValue) {
    return;
  }

  const parser = new DOMParser();
  const dom = parser.parseFromString(value, 'text/html');
  const nodes = $generateNodesFromDOM(editor, dom);

  root.clear();
  root.select();

  $insertNodes(nodes);
};
