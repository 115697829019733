import { isObject } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';
import { memo, useCallback, useState } from 'react';
import { Chip } from '@components/elements/Chips/Chip';
import type { ChipProps } from '@components/elements/Chips/Chip';
import { ChipDropdown } from '@components/elements/Chips/ChipDropdown';
import type { ChipDropdownProps } from '@components/elements/Chips/ChipDropdown';
import { ChipType } from '@components/elements/Chips';
import { styles } from './styles';

interface IfaceChipList {
  list: (ChipProps | (ChipDropdownProps & { type?: ChipType }))[];
  limited?: boolean;
  collapsible?: boolean;
  testid?: string;
}

export const ChipList = memo(({ list, limited, collapsible, testid = 'chip-list' }: IfaceChipList) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const cleanList = list?.filter?.(isObject);

  const onToggle = useCallback(() => setOpen(p => !p), []);

  if (!cleanList?.length) return null;

  return (
    <div
      data-testid={testid}
      data-selector="chip-list"
      css={[styles.chipList, !!limited && styles.chipListLimited, !!collapsible && !isOpen && styles.collapsed]}
    >
      {!!collapsible && (
        <CTA testid="toggle-chip-collapse" type={CTAType.SEEMLESS} icon={isOpen ? 'keyboard_arrow_down' : 'chevron_right'} onClick={onToggle} />
      )}
      {list.map(({ type, ...props }, index) =>
        type === ChipType.DROPDOWN ? <ChipDropdown key={`${props.id}-${index}`} {...props} /> : <Chip key={`${props.id}-${index}`} {...props} />
      )}
    </div>
  );
});
