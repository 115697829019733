// istanbul ignore file
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EMPTY, catchError, mergeMap, of, takeUntil, tap } from 'rxjs';
import type { GeneralModel } from '@cyferd/client-engine';
import {
  ApiModel,
  ViewFormationContext,
  ViewModel,
  isDeepEqual,
  ofType,
  swallowError,
  tapOnSuccess,
  useFinalizeWhileMounted,
  usePrevious,
  useUnmountObservable
} from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useEvaluate } from './useEvaluate';

type DataSourceParams = ViewModel.CyTableProps['data'];

const buildQuery = (data: DataSourceParams) => ({
  cursor: {
    ...data.initialCursor,
    collectionId: data.collectionId,
    ...(data.fixedFilter && { fixedFilter: data.fixedFilter })
  },
  ...(data.fields && { fields: data.fields }),
  ...(data.select && { select: data.select }),
  ...(data.omit && { omit: data.omit }),
  ...(data.omitAssociations && { omitAssociations: data.omitAssociations })
});

const hasChanges = (params, prevParams, query, prevQuery) => !isDeepEqual(prevQuery, query) || !isDeepEqual(prevParams, params);

const useDataSourceHandler = (dataSourceType: ViewModel.CyTableDataSourceType) => {
  const { onDataList, onCoreRunFlow } = useCyActions();

  switch (dataSourceType) {
    case ViewModel.CyTableDataSourceType.COLLECTION:
      return {
        canLoad: (_data, query) => !!query,
        canUpdateQuery: true,
        getFetchData: (_data, query) => () => onDataList({ query }),
        getNewQuery: (data, previousData) => !isDeepEqual(previousData, data) && buildQuery(data),
        hasChanges,
        shouldFetch: (_data, _prevData, query, prevQuery) => !isDeepEqual(prevQuery, query)
      };
    case ViewModel.CyTableDataSourceType.FLOW:
      return {
        canLoad: data => !!data.flowId,
        canUpdateQuery: true,
        getFetchData: data => () => onCoreRunFlow({ id: data.flowId, input: data.flowInput }),
        getNewQuery: () => null,
        hasChanges,
        shouldFetch: (data, prevData) => !isDeepEqual(prevData, data)
      };
    case ViewModel.CyTableDataSourceType.MANUAL:
      return {
        canLoad: () => false,
        canUpdateQuery: false,
        getFetchData: () => () => EMPTY,
        getNewQuery: () => null,
        hasChanges,
        shouldFetch: () => false,
        shouldUseInitialValue: true
      };
  }
};

// TODO: Never "any" type
export interface UseDataSourceParams {
  pointer: string;
  data: DataSourceParams;
  currentValue: ViewModel.CyTableProps['value'];
}

export const useDataSource = ({ pointer, data: propsData, currentValue }: UseDataSourceParams) => {
  const { onLocalStateChange } = useContext(ViewFormationContext);
  const evaluate = useEvaluate();

  const { dataSourceType, ...params } = useMemo(() => propsData || {}, [propsData]);
  const finalize = useFinalizeWhileMounted();
  const onDestroy$ = useUnmountObservable();
  const { onDispatchUseAction } = useCyActions();

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(buildQuery(params));
  const [data, setData] = useState(() => evaluate(params, { query }));

  const previousQuery = usePrevious(query);
  const previousData = usePrevious(data);
  const previousParams = usePrevious(params);

  const handler = useDataSourceHandler(dataSourceType);

  // Evaluate data when params or query changes
  useEffect(() => {
    const hasChanges = handler.hasChanges(params, previousParams, query, previousQuery);
    if (hasChanges) setData(evaluate(params, { query }));
  }, [params, query, evaluate, previousQuery, previousParams, handler]);

  const canLoad = useMemo(() => handler?.canLoad(data, query), [handler, query, data]);

  const fetch = useCallback(() => {
    if (!canLoad || isLoading) return EMPTY;

    const fetchData = handler.getFetchData(data, query);

    return of(null).pipe(
      tap(() => setIsLoading(true)),
      mergeMap(fetchData),
      takeUntil(onDestroy$),
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      // TODO: move success/error handlers out of the pipe?
      tapOnSuccess(result => {
        onLocalStateChange?.(result, pointer);
        if (data.onSuccess) {
          onDispatchUseAction({ target: data.onSuccess, event: result });
        }
      }),
      catchError(error => {
        if (data.onError) {
          return onDispatchUseAction({
            target: data.onError,
            event: { error } as any
          });
        }
        throw error;
      }),
      finalize(() => setIsLoading(false))
    );
  }, [canLoad, isLoading, handler, query, data, finalize, onDestroy$, onDispatchUseAction, onLocalStateChange, pointer]);

  useEffect(() => {
    const newQuery = handler.getNewQuery(data, previousData);
    if (newQuery) setQuery(newQuery);
  }, [data, handler, previousData]);

  useEffect(() => {
    const shouldFetch = handler.shouldFetch(data, previousData, query, previousQuery);
    if (shouldFetch) fetch().pipe(swallowError()).subscribe();
  }, [fetch, query, handler, previousQuery, data, previousData]);

  const updateCursor = useCallback(
    (criteria: GeneralModel.FetchCriteria) => {
      if (!handler.canUpdateQuery) return EMPTY;
      setQuery(prevQuery => ({
        ...prevQuery,
        cursor: { ...prevQuery.cursor, ...criteria }
      }));
      return EMPTY;
    },
    [handler.canUpdateQuery]
  );

  const returnValue = useMemo(() => {
    if (currentValue) return currentValue;
    // We use params.initialValue instead of data.initialValue because data is evaluated earlier
    if (handler.shouldUseInitialValue && params.initialValue) return params.initialValue;
    return { query: { schema: {}, cursor: {} }, totalCount: 0 };
  }, [handler.shouldUseInitialValue, currentValue, params.initialValue]);

  return {
    value: returnValue,
    canLoad,
    isLoading,
    isFirstLoad: isLoading && !currentValue,
    fetch,
    updateCursor
  };
};
