import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeNode } from '@lexical/code';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { Toolbar } from './components/Toolbar';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { styles } from './styles';
import { ListItemNode, ListNode } from '@lexical/list';
import { editorTheme } from './theme';
import { TextNode } from 'lexical';
import { TRANS } from '@constants';
import { logger } from '@utils';
import { useTranslate } from '@cyferd/client-engine';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ExtendedTextNode } from './nodes/ExtendedTextNode';
import { InitializerPlugin } from './plugins/InitializerPlugin';
import { OnChangePlugin } from './plugins/OnChangePlugin';
import { FloatingLinkEditorPlugin } from './plugins/LinkEditorPlugin';
import { useState } from 'react';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { InfoBlockNode } from './nodes/InfoBlockNode';
import { FileNode } from './nodes/FileNode';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { SharedHistoryContext, useSharedHistoryContext } from './context';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { TableActionsPlugin } from './plugins/TableActionsPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { ListIndentPlugin } from './plugins/ListIndentPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { ORDERED_LIST, QUOTE, UNORDERED_LIST } from '@lexical/markdown';
import { isMobile } from '@utils/isMobile';

interface RichEditorCompositionProps {
  value: string;
  onChange?: (value) => void;
  isEditable: boolean;
  isFocused: boolean;
  isCollapsed?: boolean;
  isReadOnly?: boolean;
  modalOpen?: boolean;
}

export const RichEditorComposition = ({ value, onChange, isEditable, isFocused, modalOpen, isCollapsed, isReadOnly = false }: RichEditorCompositionProps) => {
  const { historyState } = useSharedHistoryContext();

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const notActive = !isFocused || !isEditable || isReadOnly;
  const isActive = isFocused && isEditable && !isReadOnly;

  const initialConfig = {
    namespace: 'Cyferd Rich Editor',
    nodes: [
      HorizontalRuleNode,
      HeadingNode,
      CodeNode,
      QuoteNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
      ExtendedTextNode,
      InfoBlockNode,
      FileNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text),
        withKlass: ExtendedTextNode
      }
    ],
    theme: editorTheme,
    onError: onError,
    editable: isEditable
  };

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        {!isMobile() && renderToolbar(isActive, isCollapsed)}

        <div css={[styles.editorWrapper(isCollapsed), notActive && styles.inactive, isReadOnly && styles.readOnly]}>
          <RichTextPlugin
            placeholder={isActive && <Placeholder />}
            contentEditable={
              <div ref={onRef}>
                <ContentEditable />
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>

        {isMobile() && renderToolbar(isActive, isCollapsed)}

        <HistoryPlugin externalHistoryState={historyState} />
        <HorizontalRulePlugin />
        <LinkPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <TabIndentationPlugin />
        <ListIndentPlugin />
        <MarkdownShortcutPlugin transformers={[UNORDERED_LIST, ORDERED_LIST, QUOTE]} />
        {/*TODO: fix columns width shrinks when exceeds the editor width before become scrollable*/}
        <TablePlugin />
        <TableActionsPlugin />
        {floatingAnchorElem && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
        <InitializerPlugin value={value} modalOpen={modalOpen} isReadOnly={isReadOnly} />
        <OnChangePlugin value={value} onChange={onChange} returnType="html" />
      </SharedHistoryContext>
    </LexicalComposer>
  );
};

const renderToolbar = (isActive, isCollapsed) => isActive && <Toolbar isCollapsed={isCollapsed} />;

const Placeholder = () => {
  const { translate } = useTranslate();

  return <div css={styles.editorPlaceholder}>{translate(TRANS.RichTextEditor.placeholderValue)}</div>;
};

const onError = error => {
  logger.error(error);
};
