import { ToastStatus } from '@components/elements/Toast';
import { ApiModel, ViewModel, createUUID, ofType, tapOnSuccess } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EMPTY, catchError, tap } from 'rxjs';
import { useEvaluate } from './useEvaluate';
import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import type { Payload } from '../types';
import { PayloadSchema } from '../types';

type UseSaveBulkParams = {
  data: ViewModel.CyTableProps['data'];
  editMode: ViewModel.CyTableProps['editMode'];
  refresh: () => void;
  items: Record<string, any>;
  changeCount: number;
};

export const useSaveBulk = ({
  data: { collectionId: dataCollectionId },
  editMode: { onSaveType, collectionId, flowId, flowInput },
  refresh,
  items,
  changeCount
}: UseSaveBulkParams) => {
  const dispatch = useDispatch();
  const [conflictingValues, setConflictingValues] = useState<undefined | Payload>();
  const { onFlowRun, onDataUpdateBulk } = useCyActions();
  const evaluate = useEvaluate();

  const list = useMemo(() => Object.values(items), [items]);

  const handleGoBack = useCallback(() => {
    setConflictingValues(undefined);
  }, []);

  const handleSuccess = useCallback(() => {
    setConflictingValues(undefined);
    refresh();
    dispatch(
      uiActions.addToast({
        id: createUUID(),
        status: ToastStatus.SUCCESS,
        title: `${changeCount}${changeCount === 1 ? ' record' : ' records'} saved`
      })
    );
  }, [changeCount, dispatch, refresh]);

  const handleCancel = useCallback(() => {
    setConflictingValues(undefined);
    refresh();
  }, [refresh]);

  const handleFail = useCallback(
    (response: Payload) => {
      if (response.summary.successful) {
        dispatch(
          uiActions.addToast({
            id: createUUID(),
            status: ToastStatus.SUCCESS,
            title: `${response.summary.successful}${response.summary.successful === 1 ? ' record' : ' records'} saved`
          })
        );
      }
      setConflictingValues(response);
    },
    [dispatch]
  );

  const handleSave = useCallback(() => {
    const records = list.map(e => Object.fromEntries(Object.entries(e).filter(([, v]) => v != null)));
    const saveObservable =
      onSaveType === ViewModel.CyTableOnSaveType.DEFAULT
        ? onDataUpdateBulk({ collectionId: dataCollectionId, list: records, options: { reset: true } })
        : onSaveType === ViewModel.CyTableOnSaveType.COLLECTION
          ? onDataUpdateBulk({ collectionId, list: records, options: { reset: true } })
          : onFlowRun({ id: flowId, payload: flowInput ? evaluate(flowInput, { records }) : { list: records } });

    saveObservable
      .pipe(
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        tap(response => {
          const parsedResponse = PayloadSchema.safeParse(response);

          if ([ViewModel.CyTableOnSaveType.COLLECTION, ViewModel.CyTableOnSaveType.DEFAULT].includes(onSaveType)) {
            if (response.success) return handleSuccess();
            return handleFail(parsedResponse.data);
          }

          if (onSaveType === ViewModel.CyTableOnSaveType.FLOW) {
            if (parsedResponse.success) {
              if (parsedResponse.data.success) return handleSuccess();
              return handleFail(parsedResponse.data);
            }

            refresh();
            dispatch(
              uiActions.addToast({
                id: createUUID(),
                status: ToastStatus.SUCCESS,
                title: `Success`
              })
            );
          }
        }),
        catchError(error => {
          dispatch(
            uiActions.addToast({
              id: createUUID(),
              status: ToastStatus.ERROR,
              title: error?.payload?.error?.details?.description || 'Oh snap!',
              message: error?.payload?.error?.details?.message
            })
          );
          return EMPTY;
        })
      )
      .subscribe();
  }, [
    list,
    onSaveType,
    onDataUpdateBulk,
    dataCollectionId,
    collectionId,
    onFlowRun,
    flowId,
    flowInput,
    evaluate,
    handleSuccess,
    handleFail,
    refresh,
    dispatch
  ]);

  const handleOverrideItems = useCallback(
    (list: any[]) => {
      if (conflictingValues && [ViewModel.CyTableOnSaveType.DEFAULT, ViewModel.CyTableOnSaveType.COLLECTION].includes(onSaveType)) {
        const cleanList = list.map(e => Object.fromEntries(Object.entries(e).filter(([, v]) => v != null)));

        onDataUpdateBulk({
          collectionId: onSaveType === ViewModel.CyTableOnSaveType.DEFAULT ? dataCollectionId : collectionId,
          list: cleanList,
          options: { reset: true, force: true }
        })
          .pipe(
            ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
            tapOnSuccess(() => {
              handleSuccess();
            })
          )
          .subscribe();
      }
    },
    [conflictingValues, onSaveType, dataCollectionId, collectionId, handleSuccess, onDataUpdateBulk]
  );

  return {
    conflictingValues,
    handleOverrideItems,
    handleSave,
    handleGoBack,
    handleCancel
  };
};
